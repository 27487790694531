import React, {useEffect, useState} from 'react';
import TestGroupListNew from "./TestGroupListNew";

function TestGroup(props) {
    return (
        <div>
            <TestGroupListNew/>
        </div>
    );
}

export default TestGroup;
