import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row, Stack, Table} from "react-bootstrap";
import {Field, FieldArray, Form as FormikForm, Formik} from 'formik';
import ControlledEditor from "./ControlledEditor";
import AddNewParameterModel from "./models/AddNewParameterModel";
import {createTestGroup, getTestGroup, updateTestGroup} from "../service/Reportify";
import {toast} from "../util/toast";
import ParameterList from "./compo/ParameterList";
import {useParams} from "react-router-dom";
import ImportTestParameterModel from "./models/ImportTestParameterModel";

const getInitialValue = (testGroup) => {
    return {
        name: testGroup?.name || '',
        nameOnReport: testGroup?.nameOnReport || '',
        reportType: testGroup?.reportType || 'PARAMETER',
        endReportSummery: testGroup?.endReportSummery || '',
        testParameterIds: testGroup?.testParameters || [],
        plainText: testGroup?.plainText || '',
    }
}

function NewTestGroup(props) {
    const {testGroupId} = useParams();
    const [newParameterModalShow, setNewParameterModalShow] = useState(false)
    const [importParameterModalShow, setImportParameterModalShow] = useState(false)
    const [testGroup, setTestGroup] = useState(null)
    const [testParameterForEdit, setTestParameterForEdit] = useState(null)

    useEffect(() => {
        if (testGroupId) {
            getTestGroup(testGroupId).then(value => {
                setTestGroup(value.data)
            }).catch(reason => {
                console.log("reason", reason)
            })
        }
    }, [])

    const handelSubmit = (values, {resetForm}) => {
        const body = {...values};
        let count = -1;
        body.testParameterIds = body.testParameterIds.map((value, index) => {
            count++;
            return {
                index: count,
                testParameter: value.testParameter._id
            }
        });
        if (testGroupId) {
            updateTestGroup(testGroup._id, body).then(value => {
                toast.success('Test Group Updated')
            }).catch(reason => {
                toast.error(reason?.response?.data?.message)
            })
        } else {
            createTestGroup(body).then(value => {
                toast.success('Test Group Saved')
                resetForm();
            }).catch(reason => {
                toast.error(reason?.response?.data?.message)
            })
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' &&
            e.target.name !== 'endReportSummery' &&
            !e.target.name.includes('reference[')) {
            e.preventDefault();
        }
    };

    const handelEditTestParameter = (parameter) => {
        setTestParameterForEdit(parameter);
        setNewParameterModalShow(true)
    }

    const getTestParameters = (testParametersId) => {
      return testParametersId.map((v)=>v.testParameter)
    }

    const handelParameterPosition = (key, parameter, parameterIdsList, setFieldValue) => {
        const index = parameterIdsList.findIndex(item => item.testParameter._id === parameter._id);
        if (key === 'UP' && index > 0) {
            const temp = parameterIdsList[index];
            parameterIdsList[index] = parameterIdsList[index - 1];
            parameterIdsList[index - 1] = temp;
        } else if (key === 'DOWN' && index < parameterIdsList.length - 1) {
            const temp = parameterIdsList[index];
            parameterIdsList[index] = parameterIdsList[index + 1];
            parameterIdsList[index + 1] = temp;
        } else if (key === 'TOP' && index > 0) {
            parameterIdsList.splice(index, 1);
            parameterIdsList.unshift({index: index, testParameter: parameter});
        } else if (key === 'BOTTOM' && index < parameterIdsList.length - 1) {
            parameterIdsList.splice(index, 1);
            parameterIdsList.push({index: index, testParameter: parameter});
        } else if (key === 'REMOVE') {
            parameterIdsList.splice(index, 1);
        }
        setFieldValue('testParameterIds',
            parameterIdsList.map((pts, index) => {return {index, testParameter: pts.testParameter} }));
    }

    return (
        ((testGroupId && testGroup?._id) || !testGroupId) && <Container className={'my-3'}>
            <Formik
                initialValues={getInitialValue(testGroup)}
                enableReinitialize={true}
                onSubmit={handelSubmit}>
                {(props) => {
                    const values = props.values;
                    const setFieldValue = props.setFieldValue;
                    return <FormikForm onKeyPress={handleKeyPress}>
                        <h5>Test Group Detail</h5>
                        <Row className={'my-3'}>
                            <Form.Group xs={5} as={Col}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control as={Field} type="name" placeholder="Enter Name" id="name" name="name"/>
                            </Form.Group>

                            <Form.Group xs={5} as={Col}>
                                <Form.Label>Name On Report</Form.Label>
                                <Form.Control as={Field} type="text" id="nameOnReport" name="nameOnReport"
                                              placeholder="Enter Name For Report"/>
                            </Form.Group>

                            <Form.Group xs={2} as={Col}>
                                <Form.Label>Type</Form.Label>
                                <Field as="select" id="reportType" name="reportType" className="form-select">
                                    <option value="PARAMETER">Parameter</option>
                                    <option value="PLAIN_TEXT">Plain Text</option>
                                </Field>
                            </Form.Group>
                        </Row>
                        <Row>
                            {
                                values.reportType === 'PLAIN_TEXT' &&
                                <div className={'my-3'}>
                                    <h5>Plain Text</h5>
                                    <ControlledEditor
                                        onChange={(value) => {
                                            setFieldValue('plainText', value)
                                        }}
                                        defaultValue={values.plainText}
                                    />
                                </div>
                            }
                        </Row>

                        <Row>
                            {
                                values.reportType === 'PARAMETER' && <div>
                                    <h5>Test List</h5>
                                    <ParameterList
                                        parameterList={getTestParameters(values.testParameterIds)}
                                        onEditParameter={handelEditTestParameter}
                                        onPositionControl={(key, parameter) => {
                                            handelParameterPosition(key, parameter, values.testParameterIds, setFieldValue)
                                        }}
                                    />
                                    <Stack direction={"horizontal"} gap={3}>
                                        <Button size={"sm"}
                                                variant={'outline-primary'}
                                                className={'mx-2 ms-auto'} onClick={(e) => {
                                            e.preventDefault();
                                            setNewParameterModalShow(true)
                                        }}>Add New Test</Button>
                                        <Button size={"sm"} variant={'outline-primary'} onClick={(e) => {
                                            e.preventDefault();
                                            setImportParameterModalShow(true)
                                        }}>Import Parameters</Button>
                                    </Stack>
                                </div>
                            }
                        </Row>


                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>End Report Summary</Form.Label>

                                <ControlledEditor
                                    height={'150px'}
                                    onChange={(value) => {
                                    setFieldValue('endReportSummery', value)}}
                                    defaultValue={values.endReportSummery}
                                />
                            </Form.Group>
                        </Row>


                        <div className="d-flex justify-content-end">
                            <Button variant="primary"
                                    type="submit">{testGroup ? (testGroup.isDefault ? 'Save As New' : 'Update') : 'Save'}</Button>
                        </div>

                        <AddNewParameterModel
                            show={newParameterModalShow}
                            onHide={() => {
                                setNewParameterModalShow(false);
                                setTestParameterForEdit(null)
                            }}
                            onTestParameterCreated={(testParameter) => {
                                setFieldValue('testParameterIds', [...values.testParameterIds, {index: values.testParameterIds.length, testParameter}])
                            }}
                            testParameterForEdit={testParameterForEdit}
                            onTestParameterUpdate={(updatedTestParameter) => {
                                const updatedParameters = values.testParameterIds.map((parameter) => {
                                    if (parameter.testParameter._id === updatedTestParameter._id) {
                                        return {index: parameter.index, testParameter: updatedTestParameter};
                                    } else {
                                        return parameter;
                                    }
                                })
                                setFieldValue('testParameterIds', updatedParameters)
                            }}
                            parametersForFormula={getTestParameters(values.testParameterIds)}
                        />

                        <ImportTestParameterModel
                            show={importParameterModalShow}
                            onHide={() => {
                                setImportParameterModalShow(false);
                            }}
                            onImport={(parameterList)=>{
                                let count =  values.testParameterIds.length;
                                const newList = parameterList.map((testParameter)=>{return {index: count++, testParameter: testParameter}})
                                setFieldValue('testParameterIds', [...values.testParameterIds, ...newList])
                                setImportParameterModalShow(false);
                            }}
                        />

                    </FormikForm>
                }}
            </Formik>
        </Container>
    );
}

export default NewTestGroup;
