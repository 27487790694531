import React, {useEffect, useRef, useState} from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import {Field, useFormik} from 'formik';
import {toast} from "react-toastify";
import {createNewUser, updateUser} from "../../../service/Reportify";
import {Typeahead} from "react-bootstrap-typeahead";
import {userPermissionsList} from "../../../util/utility";

function CreateNewUserModel({ user, show, onSave, onDecline, handleClose }) {
    const ref = useRef();
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const formik = useFormik({
        initialValues: {
            userCode: user?.userCode,
            name: user?.name || '',
            email: user?.email || '',
            password: '',
            permissions: user?.permissions || [],
            userStatus: user?.userStatus || 'ACTIVE'
        },
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            console.log("submit: ", values)
            if (user){
                updateUser(values)
                    .then((value) => {
                        resetForm();
                        toast.success('User updated!');
                        onSave(value.data);
                        handleClose()
                    })
                    .catch((reason) => {
                        toast.error(reason?.response?.data?.message);
                    });
            }else {
                createNewUser(values)
                    .then((value) => {
                        resetForm();
                        toast.success('User saved!');
                        onSave(value.data);
                        handleClose()
                    })
                    .catch((reason) => {
                        toast.error(reason?.response?.data?.message);
                    });
            }
        },
    });

    useEffect(()=>{ref.current?.clear()},[user])

    useEffect(()=>{
        formik.setFieldValue('permissions', selectedPermissions)
    },[selectedPermissions])

    return (<Modal show={show} onHide={handleClose} size={"lg"} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{'Add New User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="userForm" onSubmit={formik.handleSubmit} className={'px-3'}>
                        <Form.Group className={'my-2'}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                id={"name"}
                                name="name"
                                onChange={formik.handleChange}
                                type="name"
                                placeholder="Enter Name"
                                value={formik.values.name}
                            />
                        </Form.Group>

                        <Form.Group className={'my-2'}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                id={"email"}
                                name="email"
                                onChange={formik.handleChange}
                                type="email"
                                placeholder="Enter Email"
                                value={formik.values.email}
                            />
                        </Form.Group>

                        <Form.Group className={'my-2'}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                id={"password"}
                                name="password"
                                onChange={formik.handleChange}
                                type="password"
                                placeholder="Enter Password"
                                value={formik.values.password}
                            />
                        </Form.Group>

                        <Form.Group className={'my-2'}>
                            <Form.Label>Permissions</Form.Label>
                            <Typeahead
                                ref={ref}
                                as={Field}
                                emptyLabel={'Press enter to select'}
                                labelKey={'name'}
                                onChange={(selected) => {
                                    setSelectedPermissions(selected)
                                }}
                                selected={formik.values.permissions}
                                multiple
                                options={userPermissionsList}
                                placeholder="Choose Permissions"
                                id={`permissions`}
                                name={`permissions`}
                            />
                        </Form.Group>

                        <Form.Group className={'my-2'} hidden={!user}>
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                id={'userStatus'}
                                name={'userStatus'}
                                value={formik.values.userStatus}
                                defaultValue={formik.values.userStatus}
                                onChange={formik.handleChange}>
                                <option value={'ACTIVE'}>ACTIVE</option>
                                <option value={'SUSPEND'}>SUSPEND</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onDecline}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" form={'userForm'}>
                        {user ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}

export default CreateNewUserModel;
