import React from 'react';
import {Badge, Container, Table} from "react-bootstrap";
import {displayDate} from "../util/date";
import {pointer} from "../util/style";
import MyPagination from "./MyPagination";
import {useEffect, useState} from "react";
import {getAllPatientTestGroups} from "../service/Reportify";
import {capitalizeFirstLetter, getQueryString, tableHeaderColor} from "../util/utility";
import PageTitle from "./compo/PageTitle";

function ReportList({onItemClick}) {
    const [patientTestGroups, setPatientTestGroups] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getAllPatientTestGroups(getQueryString({page: currentPage})).then(value => {
            setPatientTestGroups(value.data)
        }).catch(reason => {
        });
    }, [currentPage])

    const handelOnItemClick = (ptg) => {
        onItemClick(ptg)
    }

    return (
        <Container className={'my-3'}>
            <PageTitle title={'Patient Test Reports'} iconName={'bi-card-checklist'}/>
            <Table striped className={'mt-3'}>
                <thead>
                <tr>
                    <th style={tableHeaderColor}>Test Code</th>
                    <th style={tableHeaderColor}>Test Name</th>
                    <th style={tableHeaderColor}>Patient Id</th>
                    <th style={tableHeaderColor}>Patient Name</th>
                    <th style={tableHeaderColor}>Age - Gender</th>
                    <th style={tableHeaderColor}>Date Time</th>
                    <th style={tableHeaderColor}>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                    patientTestGroups && patientTestGroups.data.map(ptg => {
                        const badge = {}
                        if (ptg.isReportCompleted === true) {
                            badge.type = 'success';
                            badge.text = 'Completed';
                        } else if (ptg.isReportCompleted === false && ptg.report) {
                            badge.type = 'warning';
                            badge.text = 'In Progress';
                        } else {
                            badge.type = 'secondary';
                            badge.text = 'Not Completed';
                        }
                        return (
                            <tr key={ptg._id} style={pointer()} onClick={() => {
                                handelOnItemClick(ptg)
                            }}>
                                <td>{ptg.testGroupCode}</td>
                                <td>{ptg.name}</td>
                                <td>{ptg.patient.patientCode}</td>
                                <td>{ptg.patient.name}</td>
                                <td>{ptg.patient.age} {ptg.patient.ageIn?.charAt(0)} - {capitalizeFirstLetter(ptg.patient.gender)}</td>
                                <td>{displayDate(ptg.createdAt)}</td>
                                <td>
                                    <Badge bg={badge.type}>{badge.text}</Badge>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
            {
                patientTestGroups && <MyPagination currentPage={patientTestGroups.pageInfo.currentPage}
                                                   pageLimit={patientTestGroups.pageInfo.limit}
                                                   totalItem={patientTestGroups.pageInfo.totalItem}
                                                   onPageClick={(page) => {
                                                       setCurrentPage(page)
                                                   }}/>
            }
        </Container>
    );
}

export default ReportList;
