import React from 'react';
import {Card, ProgressBar} from "react-bootstrap";

function CardWithIconProgress({biIcon, iconColor, title, text, progress, progressColor}) {
    const variant = progress < 10 ? "danger" : progress < 50 ? 'warning' : 'success';

    return (
        <Card className={'p-2'} style={{borderColor: iconColor}}>
            <i className={`bi text-end ${biIcon || 'bi-pie-chart-fill'}`} style={{ fontSize: '2.5rem', color: iconColor || "gray" }} />
            <h3 style={{fontWeight: "bolder", color: iconColor}}>{text || 0}</h3>
            <h6 style={{ color: "gray", fontWeight: "bolder" }}>{title}</h6>
            <ProgressBar now={progress} style={{height: 5}} variant={variant}/>
        </Card>
    );
}

export default CardWithIconProgress;
