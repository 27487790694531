import React, {useEffect, useState} from 'react';
import ReportList from "./ReportList";
import {useNavigate} from "react-router-dom";

function Report(props) {
    const navigate = useNavigate();
    const onItemClick = (patientTestGroup) => {
        navigate(`/create-report/${patientTestGroup._id}`)
    }

    return (
        <div>
            <ReportList onItemClick={onItemClick}/>
        </div>
    );
}

export default Report;
