import React, {useEffect, useState} from 'react';
import { EditorState, convertToRaw, convertFromHTML, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ContentState from "draft-js/lib/ContentState";

function ControlledEditor({onChange, defaultValue, height}) {
    // const [isDefaultUpdated, setIsDefaultUpdated] = useState(false)
    const [editorState, setEditorState] = useState((blocks, entityMap) => {
        if (defaultValue) {
            const contentBlock = htmlToDraft(defaultValue);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, entityMap);
                return EditorState.createWithContent(contentState);
            }
        }
        return EditorState.createEmpty();
    });

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        onChange(draftToHtml(convertToRaw(contentState)));
    }, [editorState]);

    const handlePastedText = (text, html, editorState) => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text
        );

        setEditorState(EditorState.push(editorState, contentState, 'insert-characters'));
        return 'handled';
    };

    return (
        <Editor
            editorStyle={{ height: height ||  '350px', border: '1px solid #ccc' }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={editorState}
            onEditorStateChange={setEditorState}
            handlePastedText={handlePastedText}
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
            }}
        />
    );
};

export default ControlledEditor;
