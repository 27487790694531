import React, {useState} from 'react';
import {Button, Col, Container, FloatingLabel, Form, Image, InputGroup, Row, Stack} from "react-bootstrap";
import image from './../assets/3823840.jpg'
import logoImage from './../assets/logo.png'
import {useFormik} from "formik";
import {createTenantAndUser} from "../service/Reportify";
import {toast} from "../util/toast";
import {useNavigate} from "react-router-dom";
import YesNoModel from "./models/YesNoModel";

const initialValues = {
    email: '',
    name: '',
    subscriptionPlan: 'DEMO'
}

function SignUp() {
    const navigator = useNavigate();
    const [isTermChecked, setTermChecked] = useState(false);
    const [registered, setRegistered] = useState(false)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const signUpRequestCreateTenant = async (values, {resetForm}) => {
        if (values.email && values.name && values.subscriptionPlan)
            createTenantAndUser(values)
                .then(value => {
                    setRegistered(true)
                    setShowDeleteModel(true)
                    console.log('then ',value )
                    resetForm();
                    toast.success('Registration successful')
                })
                .catch(reason => {
                    toast.error(reason.response.data.message)
                })
    }

    const formik = useFormik({
        initialValues,
        onSubmit: signUpRequestCreateTenant,
    })


    return (
        <Container className="d-flex align-items-center justify-content-center vh-100">
            <YesNoModel
                show={showDeleteModel}
                handleClose={()=>{setShowDeleteModel(false)}}
                title={'Registration Complete'}
                body={`Please check your email for generate password`}
                acceptButtonLabel={'Ok'}
                declineButtonLabel={'Close'}
                onAccept={()=>{setShowDeleteModel(false)}}
                onDecline={()=>{setShowDeleteModel(false)}}
            />
            <Row className="d-flex align-items-center justify-content-center">
                <Col sm={8} style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                        <Image src={image} fluid height={'100%'}/>
                </Col>
                <Col sm={4} className={"p-3"} style={{
                    height: '100%',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <Image src={logoImage} fluid width={'100%'}/>
                    <h4 className={'mt-5'}>Register your diagnostic center</h4>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Diagnostic center name"
                            className="my-3">
                            <Form.Control type="name" placeholder="SRL Diagnostic"
                                          name='name'
                                          onChange={formik.handleChange}
                                          value={formik.values.name}

                            />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Business email address"
                            className="my-3"
                        >
                            <Form.Control type="email" placeholder="name@example.com"
                                          name='email'
                                          value={formik.values.email}
                                          onChange={formik.handleChange}
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Plan" hidden={true}>
                            <Form.Select disabled={true}
                                         onChange={formik.handleChange}
                                         name='subscriptionPlan'
                                         value={formik.values.subscriptionPlan}>
                                <option value="DEMO">Demo</option>
                            </Form.Select>
                        </FloatingLabel>

                        <Form.Check className={'my-3'}>
                            <Form.Check.Input type="checkbox"
                                              id="termsCheckbox"
                                              checked={isTermChecked}
                                              onChange={()=>{setTermChecked(!isTermChecked)}}/>
                            <Form.Check.Label htmlFor="termsCheckbox">
                                I accept the <a href="#">Terms and Conditions</a>
                            </Form.Check.Label>
                        </Form.Check>
                        <Button variant="primary" type="submit" disabled={!isTermChecked} className={'w-100'}>Register</Button>
                        {registered && <p className={'my-3'} style={{color: "darkgreen"}}>Please check your registered email for
                            generate password</p>}
                    </Form>
                    <p className="text-center fw-bold mx-3 mb-0 text-muted my-3">OR</p>
                    <Button variant={'outline-primary'} className={'w-100 my-3'} onClick={()=>{navigator('/')}}>Already have account</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default SignUp;
