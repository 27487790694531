import React, {useEffect, useRef, useState} from 'react';
import {Col, ListGroup, Row, Form, FloatingLabel, Button, Badge} from "react-bootstrap";
import {getAllTestGroups} from "../../service/Reportify";
import {getQueryString} from "../../util/utility";

function TestGroupSearchBox({onItemClicked, maxHeight, defaultTestGroupList}) {
    const [searchBoxText, setSearchBoxText] = useState('');
    const [testGroups, setTestGroups] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef();

    const handelSearch = () => {
        if (searchBoxText.trim().length > 1 || searchBoxText.trim().length === 0) {
            setPage(1)
            setLoading(false)
            setHasMore(true)
            setTestGroups([])
            fetchData(1)
        }
    }

    const fetchData = async (_page) => {
        if (loading || !hasMore) return;

        setLoading(true);
        // Fetch data from the server using the current page number

        const query = {
            search: searchBoxText.trim(),
            page: _page || page
        }
        getAllTestGroups(getQueryString(query))
            .then(value => {
                setTestGroups((prevData) => [...prevData, ...value.data.data]);
                if (value.data.data.length === 0) {
                    setHasMore(false);
                } else {
                    setPage((prevPage) => prevPage + 1);
                }
                setLoading(false);
            }).catch(reason => {
            setLoading(false);
        })
    };


    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollHeight - scrollTop === clientHeight) {
                fetchData();
            }
        }
    };

    useEffect(() => {
        fetchData(); // Fetch initial data
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchBoxText.trim().length === 0) {
            handelSearch();
        }
    }, [searchBoxText]); // eslint-disable-line react-hooks/exhaustive-deps

    function handelItemClick(testGroup) {
            setSearchBoxText('');
            onItemClicked(testGroup);
        }



    return (
        <div style={{border: '1px solid #ccc', borderRadius: '8px', padding: '8px' }}>
            <Row>
                <Col>
                    <Form.Control
                        className={'mb-2'}
                        type="text"
                        placeholder="Enter Name or Code"
                        name="search"
                        value={searchBoxText}
                        onChange={(e) => {
                            setSearchBoxText(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent the form from being submitted
                                handelSearch()
                                // Add any other logic you want to perform on Enter press
                            }
                        }}
                    />
                </Col>
                <Col sm={"auto"}>
                    <Button variant={"outline-success"} onClick={handelSearch}>Search</Button>
                </Col>
            </Row>
            <div
                ref={containerRef}
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '500px', // Adjust this to your preferred height
                    maxHeight: maxHeight || '500px', // Adjust this to your preferred maxHeight
                }}
                onScroll={handleScroll}
            >
                <ListGroup as="ol" numbered>
                    {testGroups.map((testGroup, index) => (
                        <ListGroup.Item
                            key={`${testGroup._id}${index}`}
                            style={{cursor: 'pointer'}}
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => handelItemClick(testGroup)}
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{testGroup.testGroupCode}</div>
                                {testGroup.name} <br/>
                                {testGroup.nameOnReport && <p className="text-muted" style={{fontSize: '14px', marginBottom: 0}}>{testGroup.nameOnReport}</p>}
                            </div>
                            {testGroup.isDefault ? <Badge bg="primary" pill>D</Badge> : ''}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                {loading && <p className={'text-center'}>Loading...</p>}
                {!hasMore && <p className={'text-center'}>No more test groups</p>}
            </div>
        </div>
    );
}

export default TestGroupSearchBox;
