import React from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import {Formik, Field, Form as FormikForm, FieldArray} from 'formik';
import {adjustInvoiceAmount, createTestGroupPrice} from '../../service/Reportify';
import { toast } from '../../util/toast';

function InvoiceAdjustBalanceModel({ invoice, show, onSave, onDecline, handleClose }) {

    const handelSubmit = (values) => {
        if (values.amount <= invoice.balance){
            adjustInvoiceAmount(invoice._id, values)
                .then(value => {
                    console.log("Values ", value.data)
                    toast.success('Balance Adjusted');
                    onSave(value.data);
                    handleClose()
                }).catch((reason) => {
                toast.error(reason?.response?.data?.message);
            });
        }else {
            toast.warning('Amount is more then balance');
        }
    }

    return (
        invoice && (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Adjust Balance'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {invoice.patientName}'s Balance amount is {invoice.balance}
                    </h5>
                    <Formik
                        initialValues={{
                            amount: 0,
                            mode: 'Cash'
                        }}
                        onSubmit={handelSubmit}
                        enableReinitialize={true}>
                        {({values, setFieldValue}) => <FormikForm id="adjustBalanceForm" >
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Amount</Form.Label>
                                    <Field
                                        className={'form-control'}
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        placeholder="Enter Amount"
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Mode</Form.Label>
                                    <Field as="select"
                                           size="sm"
                                           className={'form-select'}
                                           id={'mode'}
                                           name={'mode'}>
                                        <option value='Cash'>Cash</option>
                                        <option value="UPI">UPI</option>
                                        <option value="Card">Card</option>
                                        <option value="Other">Other</option>
                                    </Field>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Remaining balance is : {invoice.balance - values.amount}</p>
                                </Col>
                            </Row>
                        </FormikForm>
                        }
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onDecline}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" form={'adjustBalanceForm'}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    );
}

export default InvoiceAdjustBalanceModel;
