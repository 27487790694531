import React, {useEffect, useRef, useState} from 'react';
import {Badge, Button, Col, Form, Modal, Row, Stack} from "react-bootstrap";
import {Field, FieldArray, Form as FormikForm, Formik} from 'formik';
import {Typeahead} from "react-bootstrap-typeahead";
import {getRandomUUID, operatorList} from "../../util/utility";
import {createTestParameter, updateTestParameter} from "../../service/Reportify";
import {toast} from "../../util/toast";

const getInitialValue = (parameter) => {
  return {
      name: parameter?.name || "",
      isLabel: parameter?.isLabel || false,
      unit: parameter?.unit || '',
      options: parameter?.options || [],
      remark: parameter?.remark || '',
      reference: parameter?.reference || [{
          "gender": "MALE",
          "minAge": '',
          "maxAge": '',
          "value": ""
      }],
      formula: parameter?.formula || { equation: [], decimal: '1' },
  }
}

function AddNewParameterModel(props) {
    // testParameterForEdit
    const refFormulaTypeahead = useRef();
    const [formulaOptionList, setFormulaOptionList] = useState(operatorList);
    useEffect(()=>{
        if (props.parametersForFormula){
            const mySet = new Set();
            props.parametersForFormula.map((parameter)=>{
                if (!parameter.isLabel)
                        mySet.add({
                            _id: parameter._id,
                            name: parameter.name,
                            value: ''
                        })
            })
            formulaOptionList.forEach(value => mySet.add(value))
            setFormulaOptionList(Array.from(mySet))
        }
    },[props.parametersForFormula])
    // const [formulaList, setFormulaList] = useState(props.testParameterForEdit?.formula?.equation || []);

    const handelSubmit = (values, {resetForm}) => {
        if (props.testParameterForEdit) {
            //update
            updateTestParameter(props.testParameterForEdit._id, values).then(value => {
                props.onTestParameterUpdate(value.data);
                toast.success('Parameter Updated');
                resetForm();
                props.onHide();
            }).catch(reason => {
                toast.error(reason?.response?.data?.message);
            })
        }else {
            createTestParameter(values).then(value => {
                props.onTestParameterCreated(value.data);
                toast.success('Parameter Added');
                resetForm();
                props.onHide();
            }).catch(reason => {
                toast.error(reason?.response?.data?.message);
            })
        }
    }

    const handelFormulaAfterEnter = (enteredValue, values, setFieldValue) => {
        const value = enteredValue.trim();
        if (value.length > 0 && !formulaOptionList.find((fo)=>fo.name === value)) {
            setFieldValue('formula.equation', [...values.formula.equation, { id: getRandomUUID(), name: value, value: value }])
            refFormulaTypeahead?.current.clear();
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Test Parameter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'mx-2'}>
                <Formik
                    initialValues={getInitialValue(props.testParameterForEdit)}
                    enableReinitialize={true}
                    onSubmit={handelSubmit}
                >
                    {(props) => {
                        const values = props.values;
                        const setFieldValue = props.setFieldValue;
                        return <FormikForm id={'addTestParameter'}>
                            <Row className="mb-3">
                               <Form.Group xs={7} as={Col}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        as={Field}
                                        id={`name`}
                                        name={`name`}
                                        placeholder={"Enter Test Name"}/>
                                </Form.Group>

                                <Form.Group xs={2} as={Col}>
                                    <Form.Label>Label</Form.Label>
                                    <Form.Check
                                        label={"Label"}
                                        as={Field}
                                        id={`isLabel`}
                                        name={`isLabel`}
                                        placeholder={"Enter Test Name"}/>
                                </Form.Group>

                                <Form.Group xs={3} as={Col} hidden={values.isLabel}>
                                    <Form.Label>Unit</Form.Label>
                                    <Form.Control
                                        as={Field}
                                        id={`unit`}
                                        name={`unit`}
                                        placeholder={"Enter Unit"}/>
                                </Form.Group>
                            </Row>

                            <Row className="my-3" hidden={values.isLabel}>
                                <Form.Group as={Col}>
                                    <Form.Label>Options</Form.Label>
                                    <Form.Control as={Field}
                                                  type="text"
                                                  id={`.options`}
                                                  name={`.options`}
                                                  onChange={(e)=>{
                                                      const value = e.target.value?.trim();
                                                      setFieldValue('options', value.split(','))
                                                  }}
                                                  placeholder="Enter options comma , separated"/>
                                </Form.Group>
                            </Row>

                            <Row className="my-3" hidden={values.isLabel}>
                                <Form.Label>References</Form.Label>
                                <FieldArray
                                    name={`reference`}>
                                    {({
                                          push: pushParameter,
                                          remove: removeParameter
                                      }) => (
                                        values?.reference?.map((ref, refIndex) => (
                                            <div key={refIndex} className={'my-1'}>
                                                <Row>
                                                    <Form.Group
                                                        xs={2}
                                                        as={Col}>
                                                        <Form.Label>Gender</Form.Label>
                                                        <Field
                                                            defaultValue=""
                                                            as={"select"}
                                                            className={'form-select'}
                                                            id={`reference[${refIndex}].gender`}
                                                            name={`reference[${refIndex}].gender`}>
                                                            <option
                                                                value={'ALL'}>All
                                                            </option>
                                                            <option
                                                                value={'MALE'}>Male
                                                            </option>
                                                            <option
                                                                value={'FEMALE'}>Female
                                                            </option>
                                                        </Field>
                                                    </Form.Group>

                                                    <Form.Group
                                                        xs={2}
                                                        as={Col}>
                                                        <Form.Label>Min
                                                            Age</Form.Label>
                                                        <Form.Control
                                                            as={Field}
                                                            id={`reference[${refIndex}].minAge`}
                                                            name={`reference[${refIndex}].minAge`}
                                                            type={'number'}
                                                            placeholder={"Min Age"}/>
                                                    </Form.Group>

                                                    <Form.Group
                                                        xs={2}
                                                        as={Col}>
                                                        <Form.Label>Max
                                                            Age</Form.Label>
                                                        <Form.Control
                                                            as={Field}
                                                            id={`reference[${refIndex}].maxAge`}
                                                            name={`reference[${refIndex}].maxAge`}
                                                            type={'number'}
                                                            placeholder={"Max Age"}/>
                                                    </Form.Group>

                                                    <Form.Group
                                                        xs={4}
                                                        as={Col}>
                                                        <Form.Label>Normal Value or Range</Form.Label>
                                                        <Field
                                                            as="textarea" // Use "textarea" as the HTML element
                                                            placeholder="Enter - Value"
                                                            id={`reference[${refIndex}].value`}
                                                            name={`reference[${refIndex}].value`}
                                                            className="form-control"
                                                            rows="2" // Set the number of rows for the textarea
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    id={`reference[${refIndex}].value`}*/}
                                                        {/*    name={`reference[${refIndex}].value`}*/}
                                                        {/*    as={Field}*/}
                                                        {/*    placeholder={"Enter - Value"}/>*/}
                                                    </Form.Group>

                                                    <Form.Group
                                                        xs={2}
                                                        as={Col}>
                                                        <Form.Label>-</Form.Label>
                                                        <Stack
                                                            direction={"horizontal"}>
                                                            {
                                                                refIndex === values.reference.length-1 && <button
                                                                className={'btn bi bi-plus-square'}
                                                                onClick={
                                                                    (e) => {
                                                                        e.preventDefault()
                                                                        pushParameter({gender: refIndex % 2 === 0 ? 'FEMALE': 'MALE' })
                                                                    }
                                                                }/>
                                                            }
                                                            { values.reference.length > 1 &&
                                                                <button
                                                                    className={'btn bi bi-dash-square'}
                                                                    onClick={
                                                                        (e) => {
                                                                            e.preventDefault()
                                                                            removeParameter(refIndex)
                                                                        }
                                                                    }/>
                                                            }
                                                        </Stack>
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                        ))
                                    )}

                                </FieldArray>
                            </Row>

                            <Row className="my-3" hidden={values.isLabel}>
                                <Form.Group as={Col}>
                                    <Form.Label>Remark</Form.Label>
                                    <Form.Control as={Field}
                                                  type="text"
                                                  id={`remark`}
                                                  name={`remark`}
                                                  placeholder="Enter Remark"/>
                                </Form.Group>
                            </Row>

                            <Row className="my-3" hidden={values.isLabel}>
                                <Form.Group as={Col}>
                                    <Form.Label>Formula</Form.Label>
                                    <div className={'mb-2'}>
                                        {values.formula.equation.map((formula, index)=>
                                            <Badge bg={"info"} className={'mx-1'} onClick={()=>{
                                                setFieldValue('formula.equation', [...values.formula.equation.slice(0, index), ...values.formula.equation.slice(index + 1)])
                                            }}>{formula.name}<i className={'bi-x ms-2'}/></Badge>)}
                                    </div>
                                    <Typeahead
                                        ref={refFormulaTypeahead}
                                        as={Field}
                                        emptyLabel={'Press enter to select'}
                                        labelKey={'name'}
                                        onChange={(selected) => {
                                            if (selected.length > 0) {
                                                setFieldValue('formula.equation', [...values.formula.equation, ...selected])
                                                refFormulaTypeahead?.current.clear();
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter'){
                                                const value = e.target.value;
                                                handelFormulaAfterEnter(value, values, setFieldValue)
                                            }
                                        }}
                                        defaultInputValue={values.formulaText}
                                        options={formulaOptionList}
                                        placeholder="Choose Formula"
                                        id={`formulaText`}
                                        name={`formulaText`}
                                    />
                                </Form.Group>
                            </Row>
                            <Row hidden={values.isLabel}>
                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>Decimal Place</Form.Label>
                                    <Form.Select
                                        id={'formula.decimal'}
                                        name={'formula.decimal'}
                                        value={values.formula.decimal}
                                        defaultValue="1" onChange={(e)=>{
                                        setFieldValue('formula.decimal', e.target.value)
                                    }}>
                                        <option value={'1'}>.0</option>
                                        <option value={'2'}>.00</option>
                                        <option value={'3'}>.000</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </FormikForm>
                    }}
                </Formik>


            </Modal.Body>
            <Modal.Footer>
                <Button type={"submit"} form={'addTestParameter'} variant={"primary"}>Save New Test</Button>
                <Button variant={"secondary"} onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const clearFormulaText = (setFieldValue) => {
    setFieldValue('formulaText', '');
};

export default AddNewParameterModel;
