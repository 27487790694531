import React, {useEffect, useState} from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {createReferer, getReferer, updateReferer} from "../service/Reportify";
import {toast} from "../util/toast";
import {useParams} from "react-router-dom";
import * as Yup from 'yup';

function CreateReferer(props) {
    const {refererId} = useParams();
    const [initialValue, setInitialValue] = useState({
        name: '',
        mobile: '',
        email: '',
        address: '',
        type: 'DOCTOR',
        isPartner: false
    });

    useEffect(() => {
        if (refererId) {
            getReferer(refererId).then(value => {
                setInitialValue(value.data)
            })
        }
    },[])

    const handelSubmit = (values, {resetForm}) => {
        if (refererId){
            updateReferer(refererId, values).then(value => {
                toast.success('Referer updated!')
            }).catch(reason => {
                toast.error(reason.response.data.message)
            })
        } else {
            createReferer(values).then(value => {
                toast.success('Referer created!')
                resetForm()
            }).catch(reason => {
                toast.error(reason.response.data.message)
            })
        }

    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        mobile: Yup.string()
            .matches(/^[0-9]+$/, 'Mobile must be a number'),
        email: Yup.string()
            .email('Invalid email format'),
        type: Yup.string().required('Type is required'),
    });

    return (
        <Container className="my-3 w-50">
            <h3 className="text-center">New Referer</h3>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
            >
                <Form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <Field
                            type="text"
                            name="name"
                            className="form-control"
                        />
                        <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="mobile" className="form-label">
                            Mobile
                        </label>
                        <Field
                            type="text"
                            name="mobile"
                            className="form-control"
                        />
                        <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <Field
                            type="email"
                            name="email"
                            className="form-control"
                        />
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">
                            Address
                        </label>
                        <Field
                            type="address"
                            name="address"
                            className="form-control"
                        />
                        <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                            Type
                        </label>
                        <Field
                            as="select"
                            name="type"
                            className="form-select"
                        >
                            <option value="INDIVIDUAL">Individual</option>
                            <option value="DOCTOR">Doctor</option>
                            <option value="HOSPITAL">Hospital</option>
                            <option value="LAB">Lab</option>
                            <option value="OTHER">Other</option>
                        </Field>
                        <ErrorMessage
                            name="type"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="isPartner1" className="form-label">
                            Partner
                        </label>
                        <br/>
                        <Field
                            className={'me-2'}
                            type={"checkbox"}
                            id='isPartner'
                            name={"isPartner"}
                            // onChange={formik.handleChange}
                            // checked={formik.values.doctorNameOnPrint}
                        />
                        {/*<Form.Label>Report is complete</Form.Label>*/}
                        <label htmlFor="isPartner" className="form-label">
                            Enable Partner Price
                        </label>
                        <ErrorMessage
                            name="type"
                            component="div"
                            className="text-danger"
                        />
                    </div>

                    <div className="text-center">
                        <Button type="submit" variant="primary">
                            {refererId ? 'Update Referer': 'Save Referer'}
                        </Button>
                    </div>
                </Form>
            </Formik>
        </Container>
    );
}

export default CreateReferer;

