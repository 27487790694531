import React, { useEffect } from 'react'
import TenantList from './TenantList'
import { useAuth } from '../../contexts/authenticationContext';

export const SuperAdmin = () => {

  return (
    <>
        <TenantList />
    </>
  )
}
