import React from 'react';
import {Card, Col, ProgressBar, Row, Stack} from "react-bootstrap";

function CardWithIcon({biIcon, iconColor, title, text, compare}) {
    const changePercent = compare ? Number(((text-compare)/compare)*100).toFixed(0) : null;
    return (
        <Card className={'p-2'} style={{borderColor: iconColor}}>
            <Row>
                <Col sm={"auto"} className={'justify-content-center align-items-start'}>
                    <i className={`bi text-start ${biIcon || 'bi-pie-chart-fill'}`} style={{ fontSize: '3.5rem', color: iconColor || "gray" }} />
                </Col>
                <Col className={'d-flex flex-column justify-content-center align-items-start w-100'}>
                    <div>
                        <h4 style={{color: iconColor || 'black', fontWeight: "bolder"}}>{text || 0} {changePercent && <span style={{fontSize: '12px'}}>{changePercent}% <i className={changePercent <= 0 ? 'bi-arrow-down' : 'bi-arrow-up'}/></span>}</h4>
                        <h6 style={{ color: "gray", fontWeight: "bolder" }}>{title}</h6>
                    </div>
                </Col>
            </Row>
        </Card>
    );
}

export default CardWithIcon;
