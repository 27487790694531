import React, {useEffect, useState} from 'react';
import image from './../../assets/logo.png'
import {ProgressBar, Stack} from "react-bootstrap";
function SplashScreen({_progress}) {
    const [progress, setProgress] = useState(10)
    useEffect(()=>{
        if (_progress)
            setProgress(_progress)
    },[_progress])

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 20) {
                setProgress(prevProgress => prevProgress + 10);
            }else if (progress < 40) {
                setProgress(prevProgress => prevProgress + 5);
            } else if (progress < 70) {
                setProgress(prevProgress => prevProgress + 2);
            }else if (progress < 85) {
                setProgress(prevProgress => prevProgress + 1);
            } else {
                clearInterval(interval);
            }
        }, 2000);
        return () => clearInterval(interval);
    }, [progress]);

    return (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Stack direction={"vertical"} className="d-flex align-items-center justify-content-center p-5">
                <img
                    className={'my-2'}
                    src={image}
                    alt="Centered Image"
                    style={{ maxWidth: '50%', maxHeight: '100%%' }}
                />
                <ProgressBar now={progress} animated
                             label={`${progress}%`}
                             style={{ maxWidth: '50%', width: '50%' }}
                             className={'my-2'}
                />
                <h6 style={{ maxWidth: '50%', width: '50%', textAlign: "center" }}>
                    Connecting to the server. Please wait this may take a moment.</h6>
            </Stack>
        </div>
    );
}

export default SplashScreen;
