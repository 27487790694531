export const UserPermissions = {
    ALL : 'ALL',
    CREATE_TEST_GROUP : 'CREATE_TEST_GROUP',
    EDIT_TEST_GROUP : 'EDIT_TEST_GROUP',
    CREATE_REGISTRATION : 'CREATE_REGISTRATION',
    EDIT_PATIENT : 'EDIT_PATIENT',
    CREATE_REPORT : 'CREATE_REPORT',
    MANAGE_REFERER : 'MANAGE_REFERER',
    MANAGE_PAGE_SETUP : 'MANAGE_PAGE_SETUP',
    MANAGE_USER : 'MANAGE_USER',
    ADJUST_BALANCE : 'ADJUST_BALANCE',
};


export const UserType = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER',
};
