import axios from "axios";

export const uploadFile = async (preSignedUrl, file) => {
    if (!file) {
        console.log('No file selected.');
        return;
    }

    try {
        await axios.put(preSignedUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        console.log('File uploaded successfully.');
    } catch (error) {
        console.error('Error uploading file:', error);
    }
}
