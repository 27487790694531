import React, {useEffect, useState} from 'react';
import {getPage, getPatient} from "../service/Reportify";
import {useParams, useSearchParams} from "react-router-dom";
import { PDFViewer } from '@react-pdf/renderer';
import TestReportPdf from "./TestReportPdf";

function ViewPdf(props) {
    const {patientId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [patient, setPatient] = useState(null)
    const [page, setPage] = useState(null)
    useEffect(() => {
        if (!patientId) return patientId;
        getPatient(patientId).then(value => {
            setPatient(value.data)
        })
        getPage().then(value => {
            setPage(value.data[0])
        })
    },[patientId])

    // return (
    //      patient && <PdfViewerComponent pdfURL={patient?.reportUrl}/>
    // );
    return (
        patient && page && <div>
        <PDFViewer style={{ width: '100%', height: '800px' }} showToolbar={true} >
                 <TestReportPdf page={page} patient={patient} isLetterhead={searchParams.get('isLetterhead') || false}/>
        </PDFViewer>
    </div>
    )
}

export default ViewPdf;
