import React from 'react';
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {Field, useFormik} from "formik";
import A4PagePreview from "./A4PagePreview";
import {createFullPage} from "../service/Reportify";
import {toast} from "../util/toast";
import {resolveUploadFile} from "../util/utility";

const getInitialValue = (fullPage) => {
  return {
      headerHeight: fullPage?.headerHeight || '1cm',
      footerHeight: fullPage?.footerHeight || '1cm',
      padding: fullPage?.padding || '1mm',
      fullPageImageKey: fullPage?.fullPageImageKey || '',
      fullPageImageUrl: fullPage?.fullPageImageUrl,
      signatureOnPdf: fullPage?.signatureOnPdf,
      doctorNameOnPdf: fullPage?.doctorNameOnPdf,
      doctorNameOnPrint: fullPage?.doctorNameOnPrint,
      signatureOnPrint: fullPage?.signatureOnPrint,
  }
}

function PageConfig({ pageConfig }) {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValue(pageConfig?.fullPage),
        onSubmit: (values)=>{
            console.log("submit : ", values)
            saveFullPage(values)
        }
    })

    const saveFullPage = async (values) => {
        const body = {...values};
        if (typeof values.fullPageImageKey !== "string") {
            const file = values.fullPageImageKey;
            body.fullPageImageKey = await resolveUploadFile(file);
        }
        delete body.fullPageImageUrl;
        createFullPage(body).then(value => {
            toast.success('Saved')
        }).catch(reason => {
            toast.error(reason.response.data.message)
        })
    }

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className={'my-3'}>
                            <Form.Label>Letterhead Image</Form.Label>
                            <Stack direction={"horizontal"}>
                                <Form.Control
                                    type="file"
                                    name="fullPageImageKey"
                                    id="fullPageImageKey"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        console.log(file)
                                        formik.setFieldValue('fullPageImageKey', file);
                                    }}/>
                                <button
                                    className={'btn'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        formik.setFieldValue('fullPageImageKey','')
                                    }}><i className={'bi-trash-fill'}/></button>
                            </Stack>
                        </Form.Group>

                        <Form.Group className={'my-3'}>
                            <Form.Label>Padding</Form.Label>
                            <Form.Select onChange={formik.handleChange} name={'padding'} id={'padding'}
                                         defaultValue={formik.values.padding}>
                                {Array.from({length: 30}, (_, index) => {
                                    return <option selected={formik.values.padding.includes(index+'')} skey={index} value={`${index}mm`}>{`${index} mm`}</option>;
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className={'my-3'}>
                            <Form.Label>Header height</Form.Label>
                            <Form.Select onChange={formik.handleChange} name={'headerHeight'} id={'headerHeight'}
                                         defaultValue={formik.values.headerHeight}>
                                {Array.from({ length: 10 * 2 + 1 }, (_, index) => {
                                    const value = (index / 2).toFixed(1); // Increment by 0.5
                                    return (
                                        <option
                                            key={index}
                                            value={`${value}cm`}
                                            selected={formik.values.headerHeight.includes(`${value}`)}
                                        >
                                            {`${value} cm`}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className={'my-3'}>
                            <Form.Label>Footer height</Form.Label>
                            <Form.Select onChange={formik.handleChange} name={'footerHeight'} id={'footerHeight'}
                                         defaultValue={formik.values.footerHeight}>
                                {Array.from({length: 10 * 2 + 1 }, (_, index) => {
                                    const value = (index / 2).toFixed(1); // Increment by 0.5

                                    return <option key={value}
                                                   value={`${value}cm`}
                                                   selected={formik.values.footerHeight.includes(value+'')}>
                                        {`${value} cm`}
                                    </option>;
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className={'my-3'}>
                            <Form.Label>Signature</Form.Label>

                            <Form.Check
                                type={"checkbox"}
                                id='signatureOnPdf'
                                name={"signatureOnPdf"}
                                onChange={formik.handleChange}
                                checked={formik.values.signatureOnPdf}
                                label={`Signature on Letterhead`}
                            />

                            <Form.Check
                                type={"checkbox"}
                                id='doctorNameOnPdf'
                                name={"doctorNameOnPdf"}
                                onChange={formik.handleChange}
                                checked={formik.values.doctorNameOnPdf}
                                label={`Doctor Name on Letterhead`}
                            />

                            <Form.Check
                                type={"checkbox"}
                                id='signatureOnPrint'
                                name={"signatureOnPrint"}
                                onChange={formik.handleChange}
                                checked={formik.values.signatureOnPrint}
                                label={`Signature on Without Letterhead`}
                            />

                            <Form.Check
                                type={"checkbox"}
                                id='doctorNameOnPrint'
                                name={"doctorNameOnPrint"}
                                onChange={formik.handleChange}
                                checked={formik.values.doctorNameOnPrint}
                                label={`Doctor Name on Without Letterhead`}
                            />
                        </Form.Group>


                        <Button type={"submit"} className={'my-3'}>
                            Save Page Setup
                        </Button>
                    </Col>
                    <Col>
                            <h4>Preview</h4>
                            <A4PagePreview fullPage={formik.values}/>

                    </Col>
                </Row>
            </Form>


        </div>
    );
}

export default PageConfig;
