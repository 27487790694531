import React, {useEffect, useState} from 'react';
import {getAllReferer} from "../service/Reportify";
import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";
import {displayDate} from "../util/date";
import {Link, useNavigate} from "react-router-dom";
import MyPagination from "./MyPagination";
import {getQueryString, tableHeaderColor} from "../util/utility";
import {UserPermissions} from "../contexts/permissions/userPermissions";
import Restricted from "../contexts/permissions/Restricted";
import PageTitle from "./compo/PageTitle";

function RefererList(props) {
    const [referers, setReferers] = useState(null)
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(()=>{
        fetchData()
    },[currentPage])

    const fetchData = () => {
        const query = getQueryString({page: currentPage})
        getAllReferer(query).then(value => {
            setReferers(value.data)
        }).catch(reason => {
            console.log(reason)
        })
    }

    const handelEditReferer = (referer) => {
      navigate(`/referer/update/${referer._id}`)
    }

    const handelPageChange = (pageNumber) => {
      setCurrentPage(pageNumber)
    }
    return (
        <Container className={'my-3'}>
            <PageTitle title={'Referer List'} iconName={'bi-person-arms-up'}>
                <Restricted to={UserPermissions.MANAGE_REFERER}>
                    <Link to={`/referer/create`}>
                        <Button variant={"primary"}><i className={'bi bi-plus'}/> New Referer</Button>
                    </Link>
                </Restricted>
            </PageTitle>

            <Table striped hover className={'mt-3'}>
                <thead>
                <tr>
                    <th style={tableHeaderColor}>Referer Id</th>
                    <th style={tableHeaderColor}>Name</th>
                    <th style={tableHeaderColor}>Email</th>
                    <th style={tableHeaderColor}>Mobile</th>
                    <th style={tableHeaderColor}>Address</th>
                    <th style={tableHeaderColor}>Type</th>
                    <th style={tableHeaderColor}>Refer Count</th>
                    <th style={tableHeaderColor}>Price Partner</th>
                    <th style={tableHeaderColor}>Created At</th>
                    <th style={tableHeaderColor}/>
                </tr>
                </thead>
                <tbody>
                {referers && referers.data.map((referer) => (
                    <tr key={referer._id}>
                        <td>{referer.refererCode}</td>
                        <td>{referer.name}</td>
                        <td>{referer.email}</td>
                        <td>{referer.mobile}</td>
                        <td>{referer.address}</td>
                        <td>{referer.type}</td>
                        <td>{referer.referCount}</td>
                        <td>{referer.isPartner ? 'Price Partner' : 'Not Partner'}</td>
                        <td>{displayDate(referer.createdAt)}</td>
                        <td><button className={'btn p-0'} onClick={(e)=>{
                            e.stopPropagation()
                            handelEditReferer(referer);
                        }}><i className={'bi-pencil-square'}/></button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {
                referers && <MyPagination currentPage={referers.pageInfo.currentPage} pageLimit={referers.pageInfo.limit} totalItem={referers.pageInfo.totalItem} onPageClick={handelPageChange}/>
            }
        </Container>
    );
}



export default RefererList;
