import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import PageTitle from '../compo/PageTitle'
import { getTenantList } from '../../service/Reportify'
import { toast } from '../../util/toast'
import { displayDate, displayRelativeDate } from '../../util/date'

const TenantList = () => {
    const [tenantList, setTenantList] = useState([])
    useEffect(() => {
        getTenantList()
            .then(value => setTenantList(value.data))
            .catch(reason => toast.error(reason?.response?.data?.message))
    },[])

  return (
    <Container className={'my-3'}>
      <PageTitle title={'Tenant List'} iconName={'bi-people-fill'}></PageTitle>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tenant Code</th>
            <th>Business Name</th>
            <th>Business Email</th>
            <th>Date of Creation</th>
            <th>Tenant Status</th>
            <th>Subscription Type</th>
            <th>Email Verified</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {tenantList.map(({ tenantId, tenantCode, businessName, businessEmail, dataOfCreation, tenantStatus, subscriptionType, userEmailVerified, lastActivity }) => (
            <tr key={tenantId}>
              <td>{tenantCode}</td>
              <td>{businessName}</td>
              <td>{businessEmail}</td>
              <td>{displayDate(dataOfCreation)}</td>
              <td>{tenantStatus}</td>
              <td>{subscriptionType}</td>
              <td>{userEmailVerified ? 'Yes' : 'No'}</td>
              <td>{displayRelativeDate(lastActivity)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default TenantList