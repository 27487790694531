import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {Field, Form as FormikForm, Formik} from 'formik';
import PatientDetailComponent from "./PatientDetailComponent";
import {getPatientTestGroup, updatePatientTestGroup} from "../service/Reportify";
import {isFloat, operatorList} from "../util/utility";
import {toast} from "../util/toast";
import {Typeahead} from "react-bootstrap-typeahead";
import ControlledEditor from "./ControlledEditor";
import { getReportingDateFormate } from '../util/date';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from "moment-timezone";

const convertIntoInitialValue = ({testGroup, patient, report, reportingTime}) => {
    const previousValue = {}
    report?.parametersValues?.forEach((pv) => {
        previousValue[pv._id] = pv;
    })


    const calculateReference = (refs, _age, ageIn, gender) => {
        let age = _age;
        if (ageIn){
            if (ageIn === 'MONTHS'){
                age = age/12;
            }else if (ageIn === 'DAYS'){
                age = age/365;
            }
        }
        const genderFilter = refs.filter((ref) => ref.gender === gender)
        for (const ref of genderFilter) {
            if (age >= ref.minAge && age <= ref.maxAge) {
                return ref.value;
            }
        }
    }

    const getIfExist = (oldV, newVal) => {
        if (oldV === undefined) {
            return newVal;
        } else {
            return oldV;
        }
    }
    const parameters = (parameters) => {
        return parameters
        .map((para, pInd) => {
            const pv = previousValue[para._id];
            return {
                "_id": para._id,
                "name": para.name,
                "isLabel": para.isLabel,
                "unit": getIfExist(pv?.unit, para.unit),
                "value": getIfExist(pv?.value, ''),
                "formula": para.formula,
                'options': para.options,
                "remark": getIfExist(pv?.remark, para.remark),
                "reference": pv?.reference ? Array.isArray(pv.reference) ? calculateReference(para.reference, patient.age, patient.ageIn, patient.gender) : pv.reference : calculateReference(para.reference, patient.age, patient.ageIn, patient.gender),
            }
        })
    }

    return {
        _id: testGroup?._id,
        endReportSummery: report?.endReportSummery || testGroup?.endReportSummery,
        plainText: report?.plainText || testGroup?.plainText,
        parametersValues: parameters(testGroup.testParameters.map(tp => tp.testParameter)),
        isReportCompleted: false,
        reportingTime: reportingTime ? getReportingDateFormate(new Date(reportingTime)) : getReportingDateFormate(new Date()),
    }
}

function CreateReport(props) {
    const {patientTestGroupId} = useParams();
    const [patientTestGroup, setPatientTestGroup] = useState(null)
    const [initialValue, setInitialValue] = useState(null)
    const [focusList, setFocusList] = useState([])

    useEffect(() => {
        getPatientTestGroup(patientTestGroupId).then(value => {
            const data = value.data;
            // const testParameters = data.testGroup.testParameters;
            // data.testGroup.testParameters = testParameters.filter(tp => tp.testParameter !== null);
            setPatientTestGroup(data)
        }).catch(reason => {
            console.log('not fund')
        });
    }, [])

    useEffect(() => {
        if (patientTestGroup) {
            setInitialValue(convertIntoInitialValue(patientTestGroup))
            const allNonLabel = patientTestGroup.testGroup.testParameters.filter((tp) => tp.testParameter.isLabel === false)
            setFocusList(allNonLabel.map(pr=> pr.testParameter._id))
        }
    }, [patientTestGroup])

    const onSubmitForm = (values, {resetForm}) => {
        const data = {
            reportingTime: moment(values.reportingTime).utc().toDate(),
            isReportCompleted: values.isReportCompleted,
            report: {
                parametersValues: values.parametersValues,
                endReportSummery: values.endReportSummery,
                plainText: values.plainText
            },
        }
        updatePatientTestGroup(patientTestGroupId, data).then(value => {
            toast.success('Report saved')
        }).catch(reason => {
            console.log("resaon", reason);
            toast.error(reason.response.data.message)
        })
    }

    const getCalculatedValue = (formulaPara, extractedParameters) => {
        let stringEq = '';
        for (const eq of formulaPara.formula.equation) {
            const condition = (para) => para._id === eq._id;
            let para = extractedParameters.find(condition);
            if (!para) {
                para = eq;
            }
            stringEq = `${stringEq}${para.value ? para.value : 0}`
        }
        const output = eval(stringEq);
        return isFloat(output) ? output.toFixed(formulaPara.formula.decimal) : output;
    }

    const calculate = (pIndex, values, setValues) => {
        const formulaPara = values.parametersValues[pIndex];
        const extractedParameters = [...values.parametersValues];
        extractedParameters.push(...operatorList)
        try {
            formulaPara.value = getCalculatedValue(formulaPara, extractedParameters);
            values.parametersValues[pIndex] = formulaPara;
            setValues(values)
        } catch (e) {
            console.log("wrong equation")
        }
    }

    const calculateIfEligible = (pIndex, values, setValues) => {
        const formulaPara = values.parametersValues[pIndex];
        if (formulaPara?.formula?.equation.length > 0 && values.parametersValues[pIndex].options.length === 0) {
            calculate(pIndex, values, setValues);
        }
    }

    const handleKeyPress = (event, currentId) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            let focusIndex = focusList.indexOf(currentId);
            if (!event.shiftKey) {
                focusIndex++;
            } else {
                focusIndex--;
            }

            if (focusIndex >= 0 && focusIndex < focusList.length) {
                document.getElementById(focusList[focusIndex])?.focus();
            }
        }
    };


    const log = (value) => {
        console.log("LOG : ", value)
    }
    return (
        patientTestGroup && initialValue && <Container className={'my-3'}>
            <h5>Patient Detail</h5>
            <PatientDetailComponent patient={patientTestGroup.patient}/>
            <h5 className={'my-3'}>{patientTestGroup.testGroupCode} - {patientTestGroup.name}</h5>

            <Formik
                initialValues={initialValue}
                onSubmit={onSubmitForm}
                enableReinitialize={true}
            >
                {(prop) => {

                    const values = prop.values;
                    const setValues = prop.setValues;
                    const setFieldValue = prop.setFieldValue;
                    return <FormikForm>
                        {

                            patientTestGroup?.testGroup.reportType === 'PARAMETER' && <>
                                <Row style={{backgroundColor: 'gray'}}>
                                    <Col sm={3}>
                                        <strong>Test Name</strong>
                                    </Col>
                                    <Col sm={4}>
                                        <strong>Value</strong>
                                    </Col>
                                    <Col sm={2}>
                                        <strong>Unit</strong>
                                    </Col>
                                    <Col sm={3}>
                                        <strong>Reference</strong>
                                    </Col>
                                </Row>
                                {
                                    values?.parametersValues && values.parametersValues
                                    .map((parameter, pIndex) => {
                                        // const parameter = val.testParameter;
                                        return <div key={pIndex}>
                                            <Row className={'mt-2'}>
                                                <Form.Label column lg={3} style={parameter.isLabel ? {
                                                    fontWeight: 'bold',
                                                    textDecoration: 'underline'
                                                } : {}}>{parameter.name}</Form.Label>
                                                <Col sm={4} hidden={parameter.isLabel}>
                                                    <Stack direction={"horizontal"}>
                                                        {
                                                            parameter.options && parameter.options.length > 0 ?
                                                                <Typeahead as={Field}
                                                                           className={'w-100'}
                                                                           onChange={(selected) => {
                                                                               const value = (selected.length > 0) ? selected[0] : '';
                                                                               setFieldValue(`parametersValues[${pIndex}].value`, value);
                                                                           }}
                                                                           options={parameter.options}
                                                                           placeholder="Choose options"
                                                                           defaultSelected={parameter.value ? [parameter.value] : []}
                                                                           id={parameter._id}
                                                                           name={`parametersValues[${pIndex}].value`}
                                                                           onFocus={() => {
                                                                               calculateIfEligible(pIndex, values, setValues)
                                                                           }}
                                                                           onKeyDown={(e) => handleKeyPress(e, parameter._id)}
                                                                /> : <Form.Control
                                                                    as={Field}
                                                                    type="text"
                                                                    placeholder="Enter Value"
                                                                    id={parameter._id}
                                                                    name={`parametersValues[${pIndex}].value`}
                                                                    onKeyDown={(e) => handleKeyPress(e, parameter._id)}
                                                                    onFocus={() => {
                                                                        calculateIfEligible(pIndex, values, setValues);
                                                                    }}
                                                                    onChange={(e) => setFieldValue(`parametersValues[${pIndex}].value`, e.target.value)}
                                                                />


                                                        }
                                                        {
                                                            //formula
                                                            parameter.formula?.equation.length > 0 && values.parametersValues[pIndex].options.length === 0 &&
                                                            <button className={'btn'} onClick={(e) => {
                                                                e.preventDefault();
                                                                calculate(pIndex, values, setValues)
                                                            }}>
                                                                <i className={'bi-calculator'}/>
                                                            </button>
                                                        }
                                                    </Stack>

                                                </Col>
                                                <Col sm={2} hidden={parameter.isLabel}>
                                                    <Form.Control as={Field} type="text" placeholder="Enter Unit"
                                                                  id={`parametersValues[${pIndex}].unit`}
                                                                  name={`parametersValues[${pIndex}].unit`}
                                                        // value={formik.values.parametersGroups[pgIndex].parametersValues[pIndex].unit}
                                                        // onChange={formik.handleChange}
                                                    />
                                                </Col>
                                                <Col sm={3} hidden={parameter.isLabel}>

                                                    <Field
                                                        as="textarea" // Use "textarea" as the HTML element
                                                        placeholder="Enter - Value"
                                                        id={`parametersValues[${pIndex}].reference`}
                                                        name={`parametersValues[${pIndex}].reference`}
                                                        className="form-control"
                                                        rows="2" // Set the number of rows for the textarea
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    })
                                }
                            </>
                        }
                        {
                            patientTestGroup?.testGroup?.reportType === 'PLAIN_TEXT' && values && <div>
                                <ControlledEditor defaultValue={values.plainText} onChange={(value) => {
                                    setFieldValue('plainText', value)
                                }}/>
                            </div>
                        }

                        <Form.Group className="my-3">
                            <Form.Label>End Report Summary</Form.Label>
                            <ControlledEditor
                                height={'150px'}
                                defaultValue={values.endReportSummery}
                                onChange={(value) => {
                                setFieldValue('endReportSummery', value)
                            }}/>
                        </Form.Group>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Form.Label>Reporting time</Form.Label>
                            <Datetime
                                as={Field}
                                className={'mx-3'}
                                id="reportingTime"
                                name="reportingTime"
                                defaultValue={values.reportingTime}
                                value={values.reportingTime}
                                inputProps={{ style: {width: '220px', readOnly: true, cursor: 'pointer'} }}
                                dateFormat="DD-MMM-yyyy"
                                timeFormat="hh:mm:A"
                                onChange={(momentObj) => {
                                    console.log("momentObj", typeof momentObj)
                                    if(typeof momentObj === 'object' ) {
                                        if(momentObj.isAfter(moment())) {
                                            toast.error('Reporting time can not be future time');
                                        } else {
                                            setFieldValue('reportingTime', momentObj.format('DD-MMM-yyyy hh:mm:A'))
                                        }
                                    }
                                }}
                            />
                            <Form.Label>Report is complete</Form.Label>
                            <Field className={'mx-3'}
                                type="checkbox"
                                id="isReportCompleted"
                                name="isReportCompleted"
                            />
                            <Button className={''} type={"submit"}>Submit</Button>
                        </div>
                    </FormikForm>
                }}
            </Formik>
        </Container>

    );
}

export default CreateReport;
