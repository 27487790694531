import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkToken: () => {},
  user: JSON.parse(window.localStorage.getItem("user")),
});

export default function AuthContextProvider({ children }) {
  const [isLoggedIn, setLogin] = useState(false);
  const [user, setUser]= useState(null);
  const location = window.location;
  const path = location.pathname.split('/')[1] || '';
  const navigate = useNavigate();

  useEffect( () => {
    checkToken();
  },[]);

  function login(u) {
    const token = u.accessToken;
    setUser(u)
    setLogin(true);
    if (token) {
      window.localStorage.setItem("user", JSON.stringify(u))

      if (location.pathname === "/" || path === "sign-up" ) {
        navigate('/home', { replace: true })
      }
    }
  }

  function logout() {
    setLogin(false);
    window.localStorage.removeItem("user")
    if (['reset-password', 'sign-up', 'public'].includes(path)){
      setUser(null)
    }else if(window.location.pathname !== "/")
      navigate('/', { replace: true })
    setUser(null)
  }

  function checkToken() {
    const userString = window.localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      login(user);
    } else {
      logout();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        checkToken,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
