import React, {useState} from 'react';
import {Badge, Button, Col, Form, ListGroup, Modal, Row, Stack} from "react-bootstrap";
import {Field, Form as FormikForm, Formik} from 'formik';
import {getTestParameterByIds} from "../../service/Reportify";
import TestGroupSearchBox from "../compo/TestGroupSearchBox";

function ImportTestParameterModel(props) {

    const [parameterList, setParameterList] = useState([]);

    const handelSubmit = (values, {resetForm}) => {
        getTestParameterByIds(values.selectedParameters)
            .then(value => {
                props.onImport(value.data)
        })
    }

    const handelTestGroupSelected = (testGroup) => {
        console.log("TG ,", testGroup)
        const parameterIds = testGroup.testParameters.map(tp => tp.testParameter) || [];
        if (parameterIds.length > 0) {
            getTestParameterByIds(parameterIds).then(value => {
                console.log("TGP", value)
                setParameterList(value.data)
            })
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Import Test Parameter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'mx-2'}>
                <Row>
                    <Col sm={6}>
                        <TestGroupSearchBox onItemClicked={(tg) => {
                            handelTestGroupSelected(tg)
                        }}/>

                    </Col>
                    <Col sm={6}>
                        <Formik
                            initialValues={{}}
                            enableReinitialize={true}
                            onSubmit={handelSubmit}>
                            {(props) => {
                                const values = props.values;
                                const setFieldValue = props.setFieldValue;
                                return <FormikForm id={'addTestParameter'}>
                                    <Stack direction={"horizontal"}><h5>Parameter List</h5><Badge bg={"secondary"} className={'mx-5'} pill>{values?.selectedParameters?.length}</Badge> </Stack>
                                    <ListGroup as="ol">
                                        {
                                            parameterList.map(parameter =>
                                                <ListGroup.Item as="li">
                                                    <Stack direction={"vertical"}>
                                                        <div>{parameter.name}</div>
                                                        <div>
                                                            <Field
                                                                type="checkbox"
                                                                id={parameter._id}
                                                                name="selectedParameters"
                                                                value={parameter._id}
                                                                as={Form.Check}
                                                            />
                                                        </div>
                                                    </Stack>
                                                </ListGroup.Item>
                                            )
                                        }
                                    </ListGroup>
                                </FormikForm>
                            }}
                        </Formik>

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type={"submit"} form={'addTestParameter'} variant={"primary"}>Import</Button>
                <Button variant={"secondary"} onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImportTestParameterModel;
