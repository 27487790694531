import React, {useState} from 'react';
import {Dropdown, DropdownButton, Table} from "react-bootstrap";
import {toast} from "../../util/toast";
import {deleteTestParameter} from "../../service/Reportify";
import YesNoModel from "../models/YesNoModel";

function ParameterList({parameterList, onEditParameter, onPositionControl}) {
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [parameterForDelete, setParameterForDelete] = useState(null);

    const handelDelete = () =>{
        setShowDeleteModel(false)
        if (parameterForDelete){
            deleteTestParameter(parameterForDelete._id).then(value => {
                toast.success('Test parameter deleted')
                if (onPositionControl)
                    onPositionControl('REMOVE', parameterForDelete)
            }).catch(reason => {
                toast.error(reason.response.data.message)
            })
        }
    }

    const isDisplayControl = () => {
      if (onEditParameter || onPositionControl){
          return true;
      }else {
          return false
      }
    }

    return (
        <Table striped bordered hover>
            <YesNoModel
                show={showDeleteModel}
                handleClose={()=>{setShowDeleteModel(false)}}
                title={'Delete Test Parameter'}
                body={`Do you want to delete this test parameter ${parameterForDelete?.name}`}
                acceptButtonLabel={'Delete'}
                declineButtonLabel={'Close'}
                onAccept={handelDelete}
                onDecline={()=>{setShowDeleteModel(false); setParameterForDelete(null)}}
            />
            <thead>
            <tr>
                <th>Test Name</th>
                <th>Unit</th>
                <th>Options</th>
                <th>Remark</th>
                <th>Reference</th>
                <th>Formula</th>
                {isDisplayControl() && <th/>}
            </tr>
            </thead>
            <tbody>
            {parameterList.map((parameter, pIndex) => (
                <tr key={pIndex}>
                    <td colSpan={parameter.isLabel ? 6 : 0}>{parameter.name}</td>
                    <td hidden={parameter.isLabel}>{parameter.unit}</td>
                    <td hidden={parameter.isLabel}>{parameter.options.join(',')}</td>
                    <td hidden={parameter.isLabel}>{parameter.remark}</td>
                    <td hidden={parameter.isLabel}>
                        <ul>
                            {parameter.reference.map((ref, index) => (
                                <li key={index}>
                                    {`${ref.gender !== undefined ? ref.gender[0] +' ' : ''}Age: ${ref.minAge !== undefined ? ref.minAge : ''}-${ref.maxAge !== undefined ? ref.maxAge : ''}, Value: ${ref.value !== undefined ? ref.value : ''}`}
                                </li>
                            ))}
                        </ul>
                    </td>
                    <td hidden={parameter.isLabel}>
                        { parameter?.formula?.equation?.map((eq)=> `${eq.name} ` ) }
                    </td>
                    {isDisplayControl() && <td>
                        <CustomDropdownButton
                            onEdit={onEditParameter ? () => {
                                onEditParameter(parameter)
                            } : null}
                            onDelete={() => {
                                setParameterForDelete(parameter)
                                setShowDeleteModel(true)
                            }}
                            onPositionControl={onPositionControl ? (key) => {
                                onPositionControl(key, parameter)
                            } : null}
                        />
                    </td>}
                </tr>
            ))}
            </tbody>
        </Table>
    );
}


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        className={'btn m-0 p-0 pe-1 ps-1'}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        ref={ref}>
        {children}
    </button>
));

const CustomDropdownButton = ({onEdit, onPositionControl, onDelete}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
                <i className={'bi-three-dots-vertical'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    onEdit && <>
                        <Dropdown.Item onClick={onEdit}><i className={'bi-pencil-square me-3'}/>Edit</Dropdown.Item>
                        <Dropdown.Divider/>
                    </>
                }
                {
                    onPositionControl && <>
                        <Dropdown.Item onClick={()=>{onPositionControl('TOP')}}><i className={'bi-chevron-double-up me-3'}/>Move Top</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{onPositionControl('BOTTOM')}}><i className={'bi-chevron-double-down me-3'}/>Move Bottom</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{onPositionControl('UP')}}><i className={'bi-arrow-up me-3'}/>Move Up</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{onPositionControl('DOWN')}}><i className={'bi-arrow-down me-3'}/>Move Down</Dropdown.Item>
                        <Dropdown.Item onClick={()=>{onPositionControl('REMOVE')}}><i className={'bi-x-lg me-3'}/>Remove</Dropdown.Item>
                    </>
                }
                {/* Delete button is hiddne becase it delete the actual parameter and throwing some error while on loading */}
                {/* <Dropdown.Item onClick={onDelete}><i className={'bi-trash me-3'}/>Delete</Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>
    );
};


export default ParameterList;
