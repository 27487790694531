import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Col, Container, Form, Image, Row, Stack, Tab, Tabs} from 'react-bootstrap';
import TextStyle from "./TextStyle";
import {Field, FieldArray, Form as FormikForm, Formik} from 'formik';
import {createPageHeader, getPage, getSignedUrlForUpload} from "../service/Reportify";
import {toast} from "../util/toast";
import {buildReportHeader, resolveDownload, resolveUploadFile} from "../util/utility";

function HeaderConfig({pageConfig}) {
    const handelSubmit = async (values) => {
        const body = {...values};
        if (typeof values.backgroundImageKey !== "string"){
            const file = values.backgroundImageKey;
            body.backgroundImageKey = await resolveUploadFile(file);
        }
        if (typeof values.logoImageKey !== "string"){
            const file = values.logoImageKey;
            body.logoImageKey = await resolveUploadFile(file);
        }
        createPageHeader(body).then(value => {
            toast.success('Page saved');
            console.log('values ', value)
        }).catch(reason => {
            toast.error(reason.response.data.message)
            console.log("Reason", reason)
        })
    }
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={
                    {
                        headerHeight: pageConfig?.header?.headerHeight || '6cm',
                        headerPadding: pageConfig?.header?.headerPadding || '5mm',
                        headerTexts: pageConfig?.header?.headerTexts || [],
                        backgroundImageKey: pageConfig?.header?.backgroundImageKey || '',
                        logoImageKey: pageConfig?.header?.logoImageKey || '',
                        logoSide: pageConfig?.header?.logoSide || 'left'
                    }
                } onSubmit={handelSubmit}>
                {(props) => {
                    const values = props.values;
                    const setFieldValue = props.setFieldValue;
                    return <FormikForm>
                        <Row>
                            <Form.Group as={Col} sm={2}>
                                <Form.Label>Header Height</Form.Label>
                                <Field defaultValue="" as={"select"} className={'form-select'}
                                       id={`headerHeight`}
                                       name={`headerHeight`}>
                                    {Array.from({length: 10}, (_, index) => {
                                        return <option key={index} value={`${index}cm`}>{`${index} cm`}</option>;
                                    })}
                                </Field>
                            </Form.Group>
                            <Form.Group as={Col} sm={2}>
                                <Form.Label>Header Margin</Form.Label>
                                <Field defaultValue="" as={"select"} className={'form-select'}
                                       id={`headerPadding`}
                                       name={`headerPadding`}>
                                    {Array.from({length: 20}, (_, index) => {
                                        return <option key={index} value={`${index}mm`}>{`${index} mm`}</option>;
                                    })}
                                </Field>
                            </Form.Group>
                            <Form.Group as={Col} sm={3}>
                                <Form.Label>Logo Image</Form.Label>
                                <Stack direction={"horizontal"}>
                                    <Form.Control
                                        type="file"
                                        onChange={(e)=>{
                                            const file = e.target.files[0];
                                            console.log(file)
                                            setFieldValue('logoImageKey', file)
                                        }
                                        }/>
                                    <button
                                        className={'btn'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            setFieldValue('logoImageKey','')
                                        }}><i className={'bi-trash-fill'}/></button>
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={2}>
                                <Form.Label>Logo Align</Form.Label>
                                <br/>
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant={values.logoSide === 'left' ? 'primary' : "outline-secondary"} onClick={()=>{
                                        setFieldValue('logoSide','left')
                                    }}>Left</Button>
                                    <Button variant={values.logoSide === 'right' ? 'primary' : "outline-secondary"} onClick={()=>{
                                        setFieldValue('logoSide','right')
                                    }}>Right</Button>
                                </ButtonGroup>
                            </Form.Group>

                            <Form.Group as={Col} sm={3}>
                                <Form.Label>Header Image ({getBannerSize(values.headerHeight)})</Form.Label>
                                <Stack direction={"horizontal"}>
                                    <Form.Control
                                        type="file"
                                        onChange={(e)=>{
                                            const file = e.target.files[0];
                                            console.log(file)
                                            setFieldValue('backgroundImageKey', file)
                                        }
                                        }/>
                                    <button
                                        className={'btn'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            setFieldValue('backgroundImageKey','')
                                        }}><i className={'bi-trash-fill'}/></button>
                                </Stack>
                            </Form.Group>
                        </Row>
                        <FieldArray name={'headerTexts'}>
                            {({push, remove}) => {
                                return <>
                                    {values.headerTexts.map((value, index) => {
                                        return <Row className={'my-3'} key={index}>
                                            <Col sm={11}>
                                                <TextStyle textStyle={value} onChange={(values) => {
                                                    setFieldValue(`headerTexts[${index}]`, values)
                                                }}/>
                                            </Col>
                                            <Col sm={1}>
                                                <Button size={"sm"} className={'ms-auto'} variant={"outline-danger"}
                                                        onClick={() => {
                                                            remove(index)
                                                        }}><i className={'bi-x-lg'}/></Button>
                                            </Col>
                                        </Row>
                                    })}
                                    <Button size={"sm"} className={'my-3'} variant={"outline-primary"} onClick={() => {
                                        push({index: values.headerTexts.length + 1})
                                    }}><i className={'bi-plus-lg'}/> Add Text Item</Button>

                                </>
                            }}
                        </FieldArray>
                        <br/>
                        <Button className={'my-3'} variant={'primary'} type={'submit'}>Save</Button>

                        <h5>Header Preview</h5>
                        {
                            values && buildReportHeader(values, true)
                        }
                    </FormikForm>
                }}
            </Formik>

        </div>
    );
}

const getBannerSize = (height) => {

    // const p = padding || '0mm';
    // const width = 21 - Number(`0.${p.replace('mm','')}`) * 2
    return `21cm x ${height}`
}
export default HeaderConfig;
