import React, {useEffect, useState} from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {
    capitalizeFirstLetter,
    generateBarcodeUrl,
    generateQRCodeDataUrl,
    getPublicViewReportUrl,
    resolveDownload
} from "../util/utility";
import {displayDate} from "../util/date";
import arialFont from '../../src/fonts/Arial.ttf'
import arialBoldFont from '../../src/fonts/Arial_Bold.ttf'
import arialItalicFont from '../../src/fonts/Arial_Italic.ttf'
import Html from "react-pdf-html";

Font.register({
    family: 'Arial',
    fonts: [
        { src: arialFont },
        { src: arialItalicFont, fontWeight: 400, fontStyle: 'italic' },
        { src: arialBoldFont, fontStyle: 'bold' },
    ]
});

Font.register({
    family: 'Arial_Italic',
    fonts: [
        { src: arialFont },
        { src: arialItalicFont, fontWeight: 400, fontStyle: 'italic' },
        { src: arialBoldFont, fontStyle: 'bold' },
    ]
});


Font.register({
    family: 'Arial',
    src: arialFont,
    fontWeight: 400,
});
Font.register({
    family: 'Arial_Bold',
    src: arialBoldFont,
});
Font.register({
    family: 'Arial_Italic',
    src: arialItalicFont,
    fontWeight: 400,
    fontStyle: 'italic'
});

Font.register({
     family: 'Arial',
    fonts: [{
         src: arialFont,
        fontWeight: 400,
    },
        {
            src: arialBoldFont,
            fontWeight: 700,
        }]
    })

const imageUrlSet = {};
let fullPageConfig = {};
const styles = StyleSheet.create({
    page: {
        fontSize: '10px',
        paddingTop: '3cm',
        paddingLeft: '3mm',
        paddingRight: '3mm',
        paddingBottom: '3cm',
    },
    background: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    backgroundImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    },
    line: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderTop: 1,
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0,
    },
    qrCodeImage: {
        marginTop: 5,
        width: 64,
        height: 64,
    },
    section: {
        flexGrow: 1,
    },

    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 2,
        left: 0,
        right: 2,
        textAlign: 'right',
        color: 'black',
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        border: '1px'
    },
    patientDetailContainer: {
        marginTop: 1,
        marginBottom: 1,
        flexDirection: 'row'
    },
    pageContainer: {
        flexDirection: 'row'
    },
    container: {
        border: '1.5px solid #000000',
        flexDirection: 'row',
    },
    row: {
        flexDirection: 'row',
        flex: 1,
    },
    column: {
        flex: 1,
        padding: '8px',
    },
    label: {
        fontWeight: 'bold',
    },
    barcodeContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        transform: 'rotate(90deg)',
        marginRight: '10px',
    },

});
const tableStyles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        fontSize: '10px',
        fontFamily: 'Arial'
    },
    tableRow: {
        flexDirection: "row"
    },
    tableCol: {
        padding: '1px',
        width: "42%",
    },
    tableColSm: {
        padding: '1px',
        width: "25%",
    },
    tableColTh: {
        padding: '1px',
        width: "16.5%",
    },
    tableCell: {
        padding: '1px',
        textAlign: "left",
    },
    tableCellTh: {
        padding: '1px',
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: 'Arial_Bold'
    },

    barcodeContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        transform: 'rotate(90deg)',
    },
});
const reportTableStyles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",

        fontSize: '10px',
        fontFamily: 'Arial'
    },
    tableRowTh: {
        backgroundColor:"gray",
        // borderStyle: "solid",
        // borderWidth: 1,
        flexDirection: "row"
    },
    tableRow: {
        flexDirection: "row"
    },
    tableCol_33_3: {
        padding: '1px',
        width: "33.3%",
    },
    tableCol_40: {
        padding: '1px',
        width: "40%",
    },
    tableCol_20: {
        padding: '1px',
        width: "20%",
    },
    tableCol_25: {
        padding: '1px',
        width: "25%",
    },
    tableCol_15: {
        padding: '1px',
        width: "15%",
    },
    tableColTh: {
        padding: '1px',
        width: "16.5%",
    },
    tableCell: {
        padding: '1px',
        textAlign: "left",
    },
    tableCellBold: {
        padding: '1px',
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: 'Arial_Bold'
    },
    tableCellLabel: {
        padding: '1px',
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: 'Arial_Bold',
        textDecoration: "underline",
    },
    tableCellRemark: {
        textAlign: "left",
        fontFamily: "Arial_Italic",
        fontSize: '8px'
    },
    tableCellTh: {
        padding: '1px',
        textAlign: "left",
        fontWeight: "bold",
        fontFamily: 'Arial_Bold'
    },

    barcodeContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        transform: 'rotate(90deg)',
    },
});

const Signatures = ({signatures, showDoctorName, showSignature, qrCodeDataUrl, isLastPage }) => {
    const width = 100/signatures.length+1;
  return <View style={{...reportTableStyles.tableRow}}>
                { isLastPage && <Image src={qrCodeDataUrl} style={styles.qrCodeImage} />}
              {signatures.map((sign, index)=>
                  <View style={{width: `${width}%`, padding: '1px', alignItems: "flex-end"}} key={index}>
                      {
                          showSignature && <Image src={imageUrlSet[sign.signatureImageKey]} style={{padding: 1, width: '90px', display: "flex", alignSelf: "flex-end"}}/>
                      }
                      {
                          showDoctorName && <Text style={{padding: 1, display: "flex",  textAlign: "center", fontFamily: "Arial_Bold"}}>{sign.title}</Text>
                      }
                      {
                          showDoctorName && <Text style={{padding: 1, display: "flex",  textAlign: "center"}}>{sign.subTitle}</Text>
                      }
                  </View>
              )}
          </View>

}


const PatientDetail = ({patient, reportUpdateDate}) => (
    <View style={tableStyles.table}>
        <View style={tableStyles.tableRow}>
            <View style={{width:'90%'}}>
                <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Name</Text>
                    </View>
                    <View style={tableStyles.tableCol}>
                        <Text style={tableStyles.tableCellTh}>: {patient.title} {patient.name}</Text>
                    </View>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Patient UID</Text>
                    </View>
                    <View style={tableStyles.tableColSm}>
                        <Text style={tableStyles.tableCellTh}>: {patient.patientCode}</Text>
                    </View>
                </View>
                <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Age/Gender</Text>
                    </View>
                    <View style={tableStyles.tableCol}>
                        <Text style={tableStyles.tableCell}>: {patient.age} {capitalizeFirstLetter(patient?.ageIn)} / {capitalizeFirstLetter(patient.gender)}</Text>
                    </View>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Booked on</Text>
                    </View>
                    <View style={tableStyles.tableColSm}>
                        <Text style={tableStyles.tableCell}>: {displayDate(patient.createdAt)}</Text>
                    </View>
                </View>
                <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Refer By</Text>
                    </View>
                    <View style={tableStyles.tableCol}>
                        <Text style={tableStyles.tableCell}>: {patient.referBy}</Text>
                    </View>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Collected on</Text>
                    </View>
                    <View style={tableStyles.tableColSm}>
                        <Text style={tableStyles.tableCell}>: {displayDate(patient.updatedAt)}</Text>
                    </View>
                </View>
                <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Collected By</Text>
                    </View>
                    <View style={tableStyles.tableCol}>
                        <Text style={tableStyles.tableCell}>: Main Center</Text>
                    </View>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Reported on</Text>
                    </View>
                    <View style={tableStyles.tableColSm}>
                        <Text style={tableStyles.tableCell}>: {displayDate(reportUpdateDate)}</Text>
                    </View>
                </View>
                <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}>Doctor Name</Text>
                    </View>
                    <View style={tableStyles.tableCol}>
                        <Text style={tableStyles.tableCell}>: {patient?.doctorName}</Text>
                    </View>
                    <View style={tableStyles.tableColTh}>
                        <Text style={tableStyles.tableCellTh}/>
                    </View>
                    <View style={tableStyles.tableColSm}>
                        <Text style={tableStyles.tableCell}/>
                    </View>
                </View>
            </View>
            <View style={{width:'10%', justifyContent: 'center', alignItems: 'center'}}>
                    <Image style={tableStyles.barcode} src={generateBarcodeUrl(patient.patientCode)} />
            </View>
        </View>
    </View>
);
function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
};

const htmlToPdfComponents = (html) => {
    html = html.replaceAll(`<ins>`,`<span style="text-decoration: underline">`);
    html = html.replaceAll(`</ins>`,`</span>`);
    return html;
};

const ParameterTestReport = (patientTestGroup) => {
    return <View style={reportTableStyles.table}>
        <View style={reportTableStyles.tableRowTh} fixed>
            <View style={reportTableStyles.tableCol_40}>
                <Text style={reportTableStyles.tableCellTh}>Test Name</Text>
            </View>
            <View style={reportTableStyles.tableCol_20}>
                <Text style={reportTableStyles.tableCellTh}>Observed Value</Text>
            </View>
            <View style={reportTableStyles.tableCol_15}>
                <Text style={reportTableStyles.tableCellTh}>Unit</Text>
            </View>
            <View style={reportTableStyles.tableCol_25}>
                <Text style={reportTableStyles.tableCellTh}>Reference Range</Text>
            </View>
        </View>

        <View style={{padding: '2px', alignItems: "center"}}>
            <Text style={{textAlign: "center", fontFamily: 'Arial_Bold', fontSize: '14px', textDecoration: "underline"}}>{patientTestGroup.testGroup.nameOnReport || patientTestGroup.testGroup.name}</Text>
        </View>
        {
            patientTestGroup.report?.parametersValues?.map((pv, index) => (
                pv.isLabel ? (
                    <View style={reportTableStyles.tableRow} key={index}>
                        <View style={reportTableStyles.tableCol_40}>
                            <Text style={reportTableStyles.tableCellLabel}>{pv.name}</Text>
                        </View>
                    </View>
                ) : (
                    <View style={reportTableStyles.tableRow} key={index}>
                        <View style={reportTableStyles.tableCol_40}>
                            <Text style={reportTableStyles.tableCell}>{pv.name}</Text>
                            {pv.remark ? <Text style={reportTableStyles.tableCellRemark}>{pv.remark}</Text> : ''}
                        </View>
                        <View style={reportTableStyles.tableCol_20}>
                            {
                                pv.isAbNormal ?
                                    <Text style={reportTableStyles.tableCellBold}>{pv.value}</Text> :
                                    <Text style={reportTableStyles.tableCell}>{pv.value}</Text>
                            }
                        </View>
                        <View style={reportTableStyles.tableCol_15}>
                            <Text style={reportTableStyles.tableCell}>{pv.unit}</Text>
                        </View>
                        <View style={reportTableStyles.tableCol_25}>
                            <Text style={reportTableStyles.tableCell}>{pv.reference}</Text>
                        </View>
                    </View>
                )
            ))
        }
        <View>
            <Html style={{ fontSize: 10 }}>{htmlToPdfComponents(patientTestGroup.report.endReportSummery)}</Html>
        </View>
    </View>
}

const PlainTextTestReport = (patientTestGroup) => {
    return <View style={{width: '100%'}}>
        <View style={{padding: '2px', alignItems: "center"}}>
            <Text style={{textAlign: "center", fontFamily: 'Arial_Bold', fontSize: '14px', textDecoration: "underline"}}>{patientTestGroup.testGroup.nameOnReport || patientTestGroup.testGroup.name}</Text>
        </View>
        <Html style={{ fontSize: 10 }}>{htmlToPdfComponents(patientTestGroup.report.plainText)}</Html>
        <Html style={{ fontSize: 10 }}>{htmlToPdfComponents(patientTestGroup.report.endReportSummery)}</Html>
    </View>
}

const TestReport = ({patientTestGroup}) => {
    if (patientTestGroup.testGroup.reportType === "PARAMETER" ){
        return ParameterTestReport(patientTestGroup);
    }else {
        return PlainTextTestReport(patientTestGroup);
    }
}

const TestReportPdf = ({page, patient, isLetterhead}) => {
    const [isImageResolved, setIsImageResolved] = useState(false)
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
    const [showSignature, setShowSignature] = useState(isLetterhead === 'true' ? page.fullPage.signatureOnPdf : page.fullPage.signatureOnPrint);
    const [showSignatureName, setShowSignatureName] = useState(isLetterhead === 'true' ? page.fullPage.doctorNameOnPdf : page.fullPage.doctorNameOnPrint);

    useEffect(() => {
        fullPageConfig = page.fullPage;
        const resolveImageDownload = async () => {
            imageUrlSet[fullPageConfig.fullPageImageKey] = await resolveDownload(fullPageConfig.fullPageImageKey);
            const signatures = page.signatures
            imageUrlSet[signatures[0].signatureImageKey] = await resolveDownload(signatures[0].signatureImageKey)
            if (signatures.length > 1)
                imageUrlSet[signatures[1].signatureImageKey] = await resolveDownload(signatures[1].signatureImageKey)
            if (signatures.length > 2)
                imageUrlSet[signatures[2].signatureImageKey] = await resolveDownload(signatures[2].signatureImageKey)

        }
        resolveImageDownload().then(r => {
            setIsImageResolved(true)
        })
    },[])
    useEffect(()=>{
        if (patient) {
            generateQRCodeDataUrl(getPublicViewReportUrl(patient))
                .then(value => {
                    setQrCodeDataUrl(value)
                })
        }
    },[patient])
    return (
        isImageResolved && <Document title={`${patient.name} ${displayDate(Date.now())}`} key={patient.name}>
            {
                patient.patientTestGroups.map((patientTestGroup, index) =>
                    patientTestGroup.isReportCompleted && <Page size="A4" style={{...styles.page,
                        paddingTop: fullPageConfig.headerHeight || '3cm',
                        paddingLeft: fullPageConfig.padding || '3mm',
                        paddingRight: fullPageConfig.padding || '3mm',
                        paddingBottom: sum(fullPageConfig.footerHeight, "2cm", "cm") || fullPageConfig.footerHeight}}>
                        <View style={styles.background} fixed>
                            {isLetterhead === 'true' && <Image style={styles.backgroundImage} src={imageUrlSet[fullPageConfig.fullPageImageKey]}/>}
                        </View>
                        {/*/!*Header*!/*/}
                        <View style={styles.patientDetailContainer} fixed>
                            <PatientDetail patient={patient} reportUpdateDate={patientTestGroup?.reportingTime || patientTestGroup.updatedAt }/>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: '4px'}} key={Date.now()}>
                            <TestReport patientTestGroup={patientTestGroup}/>
                        </View>
                        <View style={styles.line}/>
                        {
                            patient.patientTestGroups.length-1 === index && <View>
                                <Text style={{textAlign: "center"}}>*** End Report ***</Text>
                                
                            </View>
                        }
                        <View style={{flexDirection: "row", position: "absolute", left: fullPageConfig.padding, right: fullPageConfig.padding, bottom: fullPageConfig.footerHeight}} fixed>
                            {
                                (showSignature || showSignatureName) && <Signatures signatures={page.signatures} showSignature={showSignature} showDoctorName={showSignatureName} qrCodeDataUrl={qrCodeDataUrl} isLastPage={patient.patientTestGroups.length-1 === index} />
                            }
                        </View>
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </Page>)
            }
        </Document>
    );
};

const sum = (item1, item2, unit) => {
    const sum = parseFloat(item1.replace(unit, "")) + parseFloat(item2.replace(unit, ""));
    return `${sum}${unit}`
}


export default TestReportPdf;
