import React, {useEffect, useState} from 'react';
import {Card, Col, Container, ListGroup, Row, Stack} from "react-bootstrap";
import {getUserProfile} from "../service/Reportify";

function Profile(props) {
    const [user, setUser] = useState(null);
    useEffect(()=>{
        getUserProfile().then(value => {
            setUser(value.data)
        })
    },[])

    return (
       user && <Container>
           <h5 className={'my-3'}>User Profile</h5>
           <Card className={'my-3'}>
               <Card.Header>Business Information</Card.Header>
               <ListGroup variant="flush">
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Business Name:</strong></Col>
                           <Col sm={9}>{user.tenant.name}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Business Email:</strong></Col>
                           <Col sm={9}>{user.tenant.email}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Subscription Plan:</strong></Col>
                           <Col sm={9}>{user.tenant.subscriptionPlan}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Subscription Expire At:</strong></Col>
                           <Col sm={9}>{user.tenant?.subscriptionExpireAt || 'NaN'}</Col>
                       </Row>
                   </ListGroup.Item>
               </ListGroup>

               <Card.Header>User Information</Card.Header>
               <ListGroup variant="flush">
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Name:</strong></Col>
                           <Col sm={9}>{user.name}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Email:</strong></Col>
                           <Col sm={9}>{user.email}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>Email Verified:</strong></Col>
                           <Col sm={9}>{user.isEmailVerified ? 'Yes' : 'No'}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>User Code:</strong></Col>
                           <Col sm={9}>{user.userCode}</Col>
                       </Row>
                   </ListGroup.Item>
                   <ListGroup.Item>
                       <Row>
                           <Col sm={3}><strong>User Type:</strong></Col>
                           <Col sm={9}>{user.userType}</Col>
                       </Row>
                   </ListGroup.Item>
               </ListGroup>
           </Card>
       </Container>
    );
}

export default Profile;
