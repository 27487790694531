import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {numberToWords} from "amount-to-words/dist/main";
import {useReactToPrint} from "react-to-print";
import {buildInvoiceHeader, buildPatientDetail} from "../../print/InvoicePrintHelper";
import {getPatient} from "../../service/Reportify";

function PrintInvoiceModel(props) {
    const [patient, setPatient] = useState(null);
    const componentRef = useRef();

    useEffect(()=>{
        if (!props.invoice?.patient?._id) {
            fetchPatient(props.invoice?.patient)
        }else {
            setPatient(props.invoice.patient)
        }
    },[props.invoice?.patient])

    const fetchPatient = async (patientId) => {
        if (patientId) {
            const response = await getPatient(patientId);
            setPatient(response.data)
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
        pageStyle: `
          @media print {
            body {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
            color-adjust: exact !important;                 /* Firefox 48 – 96 */
            print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
            }
    
            @page {
              size: A4;
            }
            
            #app-name,
            #date-time {
              display: none;
            }
    
            .print-page {
              position: relative;
              page-break-before: always; /* Ensure a new page for each "print-page" div */
            }
        `,
    });
    const style = {
        width: '100%',
        padding: '2px',
        height: '2px',
    }

    const styleTextLeft = {
        border: '1px solid #000000', textAlign: 'left', padding: '2px',
    }

    const styleTextRight = {
        border: '1px solid #000000', textAlign: 'end', padding: '2px',
    }


    return (
        props.invoice && <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Print Invoice
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'mx-2'}>
                <div ref={componentRef} className={'p-1'} style={{fontSize:'14px'}}>
                    {props.pageSetup.invoice ?
                        buildInvoiceHeader(props.pageSetup?.invoice, false) :
                        <div style={{textAlign:"center", fontWeight: "bold", fontSize: '24px'}}>Cash Memo Bill</div>}
                    <div className={'my-2'} style={{border: '1px solid #000', textAlign: "center", fontWeight: "bold", padding:'2px', backgroundColor: '#f2f2f2'}}>Payment Receipt</div>
                    {patient && buildPatientDetail(patient, props.invoice)}
                    <table style={{width: '100%', borderCollapse: "collapse"}} className={'my-2'}>
                        <thead className={'bg-secondary'}>
                        <tr className={'p-2'}>
                            <th style={{
                                width: "10%",
                                backgroundColor: '#f2f2f2',
                                ...styleTextLeft
                            }}>S. No
                            </th>
                            <th style={{
                                width: "15%",
                                backgroundColor: '#f2f2f2',
                                ...styleTextLeft
                            }}>Code
                            </th>
                            <th style={{
                                width: "55%",
                                backgroundColor: '#f2f2f2',
                                ...styleTextLeft
                            }}>Test Name
                            </th>
                            <th style={{
                                width: "20%",
                                backgroundColor: '#f2f2f2',
                                ...styleTextLeft
                            }}>Amount (Rs)
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.invoice.items.map((item, index) => {
                                return <tr>
                                    <td style={styleTextLeft}>{index + 1}</td>
                                    <td style={styleTextLeft}>{item.code}</td>
                                    <td style={styleTextLeft}>{item.name}</td>
                                    <td style={styleTextRight}>{Number(item.unit * item.price).toFixed(2)}</td>
                                </tr>
                            })
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={3}
                                style={styleTextRight}>Total Bill
                                Amount (Rs)
                            </td>
                            <td style={styleTextRight}>{Number(props.invoice.total).toFixed(2)}</td>
                        </tr>
                        {props.invoice.discount !== 0 &&
                            <tr>
                                <th colSpan={3}
                                    style={styleTextRight}>Discount
                                    (Rs)
                                </th>
                                <td style={styleTextRight}>{Number(props.invoice.discount).toFixed(2)}</td>
                            </tr>}
                        <tr>
                            <th colSpan={3}
                                style={styleTextRight}>{`Amount Paid (Rupees ${numberToWords(props.invoice.payments.reduce(
                                (accumulator, currentItem) => accumulator + currentItem.amount,
                                0,
                            ))} Only)`}</th>
                            <th style={styleTextRight}>{Number(props.invoice.payments.reduce(
                                (accumulator, currentItem) => accumulator + currentItem.amount, 0,
                            )).toFixed(2)}</th>
                        </tr>
                        <tr>
                            <th colSpan={3}
                                style={styleTextRight}>Amount Due
                            </th>
                            <th style={styleTextRight}>{Number(props.invoice.balance).toFixed(2)}</th>
                        </tr>
                        </tfoot>
                    </table>
                    <div style={{textAlign: "end", marginTop: '34px'}}>Authorized Signature</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type={"submit"} variant={"primary"} onClick={handlePrint}>Print</Button>
                <Button variant={"secondary"} onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PrintInvoiceModel;
