import React, {useEffect, useState} from 'react';
import {Pagination} from "react-bootstrap";

function MyPagination({onPageClick, pageLimit, currentPage, totalItem}) {
    const [selectedPage, setSelectedPage] = useState(currentPage);
    const totalPage = Math.ceil(totalItem/pageLimit);

    useEffect(()=>{
        setSelectedPage(currentPage)
    },[currentPage])

    useEffect(()=>{
        if (selectedPage !== currentPage){
            onPageClick(selectedPage)
        }
    },[selectedPage])

    const handelClickFirst = () => {
        if (selectedPage !== 1){
            setSelectedPage(1)
        }
    }

    const handelClickLast = () => {
        if (selectedPage !== totalPage){
            setSelectedPage(totalPage)
        }
    }

    const handelItemClick = (pageNumber) => {
        if (selectedPage !== pageNumber){
            setSelectedPage(pageNumber)
        }
    }

    const handelNext = () => {
        if (selectedPage < totalPage ){
            setSelectedPage(selectedPage+1)
        }
    }

    const handelPrevious = () => {
        if (selectedPage > 1 ){
            setSelectedPage(selectedPage-1)
        }
    }
    return (
        <div className="d-flex justify-content-center">
            <Pagination>
                <Pagination.First onClick={handelClickFirst} disabled={selectedPage === 1}/>
                <Pagination.Prev onClick={handelPrevious} disabled={selectedPage <= 1}/>
                {
                    paginationItems(totalPage, selectedPage, handelItemClick)
                }
                <Pagination.Next onClick={handelNext} disabled={selectedPage >= totalPage}/>
                <Pagination.Last onClick={handelClickLast} disabled={selectedPage === totalPage}/>
            </Pagination>
        </div>
    );
}

const paginationItems = (totalPage, active, handelItemClick) => {
    const items = [];
    for (let number = 1; number <= totalPage; number++) {
        if (totalPage <= 10){
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={()=>{handelItemClick(number)}}>
                    {number}
                </Pagination.Item>,
            );
        }else if (number > active-5 && number < active+5){
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={()=>{handelItemClick(number)}}>
                    {number}
                </Pagination.Item>,
            );
        }
    }
    return items;
}

export default MyPagination;
