import React from 'react';
import {Button, Col, Container, FloatingLabel, Form, Image, InputGroup, Row} from "react-bootstrap";
import image from './../assets/3823840.jpg'
import {useFormik} from "formik";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {resetPassword} from "../service/Reportify";
import {toast} from "../util/toast";

function ResetPassword() {
    const navigator = useNavigate()
    const { verificationToken } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const resetPasswordRequest = async (values, {resetForm}) => {
        if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
            toast.warning('Password not match')
            return
        }
        if (values.email && values.password)
            resetPassword(values)
                .then(value => {
                    resetForm();
                    toast.success('Password reset successful')
                    navigate(`/`)
                })
                .catch(reason => {
                    toast.error(reason.response.data.message)
                })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues : {
            email: searchParams.get('email') || '',
            password: '',
            confirmPassword: '',
            verificationToken
        },
        onSubmit: resetPasswordRequest,
    })


    return (
        <Container style={{height: '100vh'}}>
            <Row className="d-flex align-items-center justify-content-center">
                <Col sm={8} style={{
                    height: '100%',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <Image src={image} fluid/>
                </Col>
                <Col sm={4} className={"p-3"} style={{
                    height: '100%',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <h4>Generate password</h4>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="my-3">
                            <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                type='email'
                                name='email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Password"
                            className="my-3"
                        >
                            <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                type='password'
                                name='password'
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Confirm Password"
                            className="my-3"
                        >
                            <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                type='password'
                                name='confirmPassword'
                                onChange={formik.handleChange}
                                value={formik.values.confirmPassword}
                            />
                        </FloatingLabel>
                        <Button variant="primary" type="submit" className={'w-100'}>Save</Button>
                    </Form>
                    <p className="text-center fw-bold mx-3 mb-0 text-muted my-3">OR</p>
                    <Button variant={'outline-primary'} className={'w-100 my-3'} onClick={()=>{navigator('/sign-up')}}>Already have account</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPassword;
