import React from 'react';
import { Col, Row } from 'react-bootstrap';

function PageTitle({ iconName, title, children }) {
    return (
        <Row>
            <Col>
                <h5>{iconName? <i className={`bi ${iconName}`}> </i>:''}{title}</h5>
            </Col>
            <Col sm="auto">{children}</Col>
        </Row>
    );
}

export default PageTitle;
