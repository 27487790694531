import {v4 as uuidv4} from 'uuid';
import React from "react";
import {getSignedUrlForDownload, getSignedUrlForUpload} from "../service/Reportify";
import {uploadFile} from "../service/S3";
import {Col, Image, Row} from "react-bootstrap";
import JsBarcode from "jsbarcode";
import QRCode from 'qrcode';
import {UserPermissions} from "../contexts/permissions/userPermissions";

export const getRandomUUID = () => {
    return uuidv4();
}

export const titleToGenderMap = {
    'Mr.': 'MALE',
    'Mrs.': 'FEMALE',
    'Miss': 'FEMALE',
    'Ms.': 'FEMALE',
    'Dr.': '',
    'Prof.': '',
    'Rev.': '',
    'Hon.': '',
    'Capt.': '',
    'Sir': 'MALE',
    'Lady': 'FEMALE',
    'Lord': 'MALE',
    'Esq': '',
    'Jr.': 'MALE',
    'Sr.': 'MALE',
    'Mx.': '',
};

export const operatorList = [
    {_id: '+', name: '+', value: '+'},
    {_id: '-', name: '-', value: '-'},
    {_id: '*', name: '*', value: '*'},
    {_id: '/', name: '/', value: '/'},
    {_id: '(', name: '(', value: '('},
    {_id: ')', name: ')', value: ')'}
]

export const isFloat = (value) => {
    return Number(value) === value && value % 1 !== 0;
}

export const userPermissionsList = Object.keys(UserPermissions);

export const tableHeaderColor = { backgroundColor: 'gray' }

export const getQueryString = (queryObject) => {
    return Object.keys(queryObject)
        .map((key) => `${key}=${queryObject[key]}`)
        .join('&');
}

export const buildReportHeader = (values, border) => {
    let backgroundFile = values.backgroundImageKey;
    let logoFile = values.logoImageKey;
    if (backgroundFile && typeof backgroundFile !== "string") {
        backgroundFile = URL.createObjectURL(backgroundFile)
    }
    if (typeof logoFile !== "string") {
        logoFile = URL.createObjectURL(logoFile)
    }
    const style = {
        width: '21cm',
        padding: values.headerPadding,
        height: values.headerHeight,
        border: border ? '1px solid #ccc' : '',
        backgroundImage: backgroundFile ? `url(${backgroundFile})` : '',
        backgroundSize: 'cover',
    }

    if (values.logoSide === 'left' && logoFile !== '') {
        //left side logo
        return <Row style={style}>
            <Col style={{
                maxWidth: values.headerHeight,
                maxHeight: values.headerHeight,
                padding: '2mm',
                textAlign: 'center'
            }}>
                <Image src={logoFile} className={''} style={{maxHeight: '100%', width: "auto", maxWidth: '100%'}}/>
            </Col>
            <Col style={{
                height: '100%',
                display: 'flex', flexDirection: 'column',
                justifyContent: 'center'
            }}>
                {
                    values.headerTexts.map((value, index) => {
                        return <div key={index}
                                    style={value.style}>
                            {value.text}
                        </div>
                    })
                }
            </Col>
        </Row>

    } else if (values.logoSide === 'right' && logoFile !== '') {
        //right side logo
        return <Row style={style}>
            <Col style={{
                height: '100%',
                display: 'flex', flexDirection: 'column',
                justifyContent: 'center'
            }}>
                {
                    values.headerTexts.map((value, index) => {
                        return <div key={index}
                                    style={value.style}>
                            {value.text}
                        </div>
                    })
                }
            </Col>
            <Col style={{
                maxWidth: values.headerHeight,
                maxHeight: values.headerHeight,
                padding: '2mm',
                textAlign: 'center'
            }}>
                <Image src={logoFile} className={''} style={{maxHeight: '100%', width: "auto", maxWidth: '100%'}}/>
            </Col>
        </Row>
    } else {
        return <div style={{
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', ...style
        }}>
            {
                values.headerTexts.map((value, index) => {
                    return <div key={index}
                                style={value.style}>
                        {value.text}
                    </div>
                })
            }
        </div>
    }
}

export const resolveUploadFile = async (file) => {
    const response = await getSignedUrlForUpload(file.name, {contentType: file.type})
    const fileName = response.data.fileName;
    const mediaUrl = response.data.mediaUrl;
    await uploadFile(mediaUrl, file)
    return fileName;
}
export const resolveDownload = async (fileName) => {
    if (fileName === undefined || fileName === '' || fileName.includes('http')) return fileName;
    const response = await getSignedUrlForDownload(fileName);
    return response.data.mediaUrl;
}

export const generateBarcodeUrl = (code) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, code, {
        format: 'CODE128',
        displayValue: false,
        height: 15,
        width: 1,
    });
    return canvas.toDataURL();
}

export const generateQRCodeDataUrl = async (url) => {
    const canvas = await QRCode.toCanvas(url, {width: 128, margin: 1});
    return canvas.toDataURL();
};

export const getPublicViewReportUrl = (patient) => {
  return `https://www.app.diagnogenius.com/public/report/${patient._id}/${patient.createdAt}`;
}

export const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getWhatsAppMessage = (patientName, reportUrl) => {
  return `Hi ${patientName},

I hope this message finds you well. We have generated a diagnostic report for you, and you can download it by clicking on the link below:

${reportUrl}

Feel free to reach out if you have any questions or need further assistance.

Best regards
`
}

export const getEmailMessage = (patientName, reportUrl) => {
return `Dear ${patientName},

I trust this email finds you in good health. We are pleased to inform you that your diagnostic report is now available for download. Please click on the link below to access your report:

${reportUrl}

If you have any queries or require additional information, please do not hesitate to contact us.
`
}
