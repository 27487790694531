import React from 'react';
import {Container} from "react-bootstrap";

function ViewRegistration(props) {
    return (
        <Container>View Transaction</Container>
    );
}

export default ViewRegistration;
