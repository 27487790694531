import React from 'react';
import {Button, Modal} from "react-bootstrap";

function YesNoModel({show, title, body, acceptButtonLabel, declineButtonLabel, onAccept, onDecline, handleClose}) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title || 'Please Select'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body || 'Choose one option'}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onDecline}>
                    {declineButtonLabel || 'No'}
                </Button>
                <Button variant="primary" onClick={onAccept}>
                    {acceptButtonLabel || 'Yes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default YesNoModel;
