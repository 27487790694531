import React from 'react';
import PatientList from "./PatientList";

function Patient(props) {
    return (
        <div>
            <PatientList/>
        </div>
    );
}

export default Patient;
