import React, {useEffect, useState} from 'react';
import { Container, Table} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import {getTestGroup} from "../service/Reportify";
import ParameterList from "./compo/ParameterList";

const TestGroupDisplay = () => {
    const {testGroupId} = useParams()
    const [testGroup, setTestGroup] = useState();

    useEffect(() => {
        getTestGroup(testGroupId).then(value => {
            setTestGroup(value.data);
        }).catch(reason => {
            console.log("Reason", reason)
        });
    }, [])
    const labelStyle = {
        width: '30%',
        textAlign: 'right',
        fontWeight: 'bold',
    };
    return (
        testGroup && <Container className={'my-3'}>
            <h4 className={'my-3'}>Test Group Detail</h4>
            <Table bordered>
                <tbody>
                <tr>
                    <th style={labelStyle}>Test Group Code</th>
                    <td>{testGroup.testGroupCode}</td>
                    <th style={labelStyle}>Name:</th>
                    <td>{testGroup.name}</td>
                </tr>

                <tr>
                    <th style={labelStyle}>Name On Report:</th>
                    <td>{testGroup.nameOnReport}</td>
                    <th style={labelStyle}>Report Type:</th>
                    <td>{testGroup.reportType}</td>
                </tr>
                {testGroup.reportType === "PLAIN_TEXT" &&
                    <tr>
                        <td style={labelStyle}>Plain Text</td>
                        <td colSpan={3}>
                            <div dangerouslySetInnerHTML={{__html: testGroup.plainText}}/>
                        </td>
                    </tr>
                }

                {testGroup.reportType === "PARAMETER" &&

                    <tr>
                        <td colSpan={4}>
                            <h5>Parameter List</h5>
                            <ParameterList parameterList={testGroup.testParameters.map(tp=>tp.testParameter)}/>
                        </td>
                    </tr>
                }
                <tr>
                    <td style={labelStyle}>End Report Summery</td>
                    <td colSpan={3}><div dangerouslySetInnerHTML={{__html: testGroup.endReportSummery}}/></td>
                </tr>
                </tbody>
            </Table>
        </Container>
    );
};

export default TestGroupDisplay;
