import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Container, Dropdown, Form, NavDropdown, Row, Table} from "react-bootstrap";
import {useFormik} from "formik";
import {displayDate} from "../util/date";
import {Link, useNavigate} from "react-router-dom";
import {pointer} from "../util/style";
import {
    deleteTestGroup,
    getAllPatientList,
    getAllTestGroups,
    getPriceByTestGroupIds,
    searchPatients, syncTestGroup
} from "../service/Reportify";
import {toast} from "../util/toast";
import {getQueryString, tableHeaderColor} from "../util/utility";
import MyPagination from "./MyPagination";
import YesNoModel from "./models/YesNoModel";
import TestGroupPriceModel from "./models/TestGroupPriceModel";
import {UserPermissions} from "../contexts/permissions/userPermissions";
import Restricted from "../contexts/permissions/Restricted";
import PageTitle from "./compo/PageTitle";

function TestGroupList(props) {
    const [testGroupList, setTestGroupList] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showPriceUpdateModel, setShowPriceUpdateModel] = useState(false);
    const [testGroupSelected, setTestGroupSelected] = useState(null);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {search: ''},
        onSubmit: (values) => {
            searchPatient(values)
        }
    })

    useEffect(() => {
        fetchData(formik.values.search, currentPage);
    }, [currentPage])

    const fetchData = (search, page) => {
        getAllTestGroups(getQueryString({page: page, search: search ? search : ''}))
            .then(async value => {
                const priceList = value.data;
                const idList = priceList.data.map((tg)=>{return tg._id})
                const response = await getPriceByTestGroupIds(idList);
                const priceMap = {};
                    response.data.map((price)=>{
                        priceMap[price.testGroup] = price;
                })
                priceList.data = priceList.data.map((tg)=>{
                    tg['price'] = priceMap[tg._id] || {};
                    return tg;
                })
                setTestGroupList(priceList);
            }).catch(reason => {
            console.log('reason', reason)
        })
    }

    const searchPatient = (values) => {
        if (values.search.trim().length > 1) {
            fetchData(values.search, 1);
        }
    }

    function handelItemClick(testGroup) {
        navigate(`/test-group/view/${testGroup._id}`)
    }

    const handelEditPatient = (testGroup) => {
        console.log("edit tet group ", testGroup)
        navigate(`/test-group/update/${testGroup._id}`)
    }

    const handelDelete = () => {
        if (testGroupSelected)
            deleteTestGroup(testGroupSelected._id).then(value => {
                toast.success('Test group deleted')
                setTestGroupList({pageInfo: testGroupList.pageInfo, data: testGroupList.data.map((tg)=>{
                        if (tg._id !== testGroupSelected._id)
                            return tg;
                    })})
                setTestGroupSelected(null)
                setShowDeleteModel(false)
            }).catch(reason => {
                toast.error(reason?.response?.data?.message)
                setTestGroupSelected(null)
                setShowDeleteModel(false)
            })
        // deleteTestGroup(deleteTestGroup._id)
    }

    const handelPriceUpdated = (updatedPrice) => {
        const updatedList = testGroupList.data.map((tg)=>{
            const tgId = updatedPrice.testGroup._id || updatedPrice.testGroup;
            if (tg._id === tgId) {
                tg.price = updatedPrice;
                return tg;
            }
            return tg;
        })
        setTestGroupList({pageInfo: testGroupList.pageInfo, data: updatedList})
    }

    function handelSyncClick() {
        syncTestGroup().then(value => { toast.success('Sync take some time, please wait')})
    }

    return (
        <Container className={'my-3'}>
            <PageTitle title={'Test Group List'} iconName={'bi-thermometer-high'}>
                <Restricted to={UserPermissions.CREATE_TEST_GROUP}>
                    <Link to={`/test-group/create`}>
                        <Button variant={"primary"}><i className={'bi bi-plus'}/>New Test Group</Button>
                    </Link>
                </Restricted>
            </PageTitle>
            <YesNoModel
                show={showDeleteModel}
                handleClose={()=>{setShowDeleteModel(false)}}
                title={'Delete Test Group'}
                body={`Do you want to delete this test group ${testGroupSelected?.testGroupCode}: ${testGroupSelected?.name}`}
                acceptButtonLabel={'Delete'}
                declineButtonLabel={'Close'}
                onAccept={handelDelete}
                onDecline={()=>{setShowDeleteModel(false); setTestGroupSelected(null)}}
            />

            <TestGroupPriceModel
                show={showPriceUpdateModel}
                testGroup={testGroupSelected}
                onDecline={()=>{setShowPriceUpdateModel(false); setTestGroupSelected(null)}}
                onSave={handelPriceUpdated}
                handleClose={()=>{setShowPriceUpdateModel(false)}}
            />
            <Form className={'mt-3'} onSubmit={formik.handleSubmit} onChange={(e) => {
                if (e.target.value === '') {
                    fetchData('', 1)
                }
            }}>
                <Row className={'mb-3'}>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Test Group Code, Name, Name on report"
                            name="search"
                            id={'search'}
                            onChange={formik.handleChange}
                        />
                    </Col>
                    <Col xs="auto">
                        <Button variant={"outline-success"} type={'submit'}>Search</Button>
                    </Col>
                    <Col xs="auto">
                        <button className={'btn'} onClick={handelSyncClick}><i className={'bi-arrow-repeat'}/> Sync</button>
                    </Col>
                </Row>
            </Form>
            <Table striped>
                <thead>
                <tr>
                    <th style={tableHeaderColor}>Test Group ID</th>
                    <th style={tableHeaderColor}>Name</th>
                    <th style={tableHeaderColor}>Name on Report</th>
                    <th style={tableHeaderColor}>Parameters</th>
                    <th style={tableHeaderColor}>My Price</th>
                    <th style={tableHeaderColor}>Partner Price</th>
                    <th style={tableHeaderColor}/>
                </tr>
                </thead>
                <tbody>
                {
                    testGroupList && testGroupList.data.map(testGroup => {
                        return (
                            testGroup && <tr key={testGroup._id} style={pointer()} onClick={(e) => {
                                handelItemClick(testGroup)
                            }}>

                                <td>{testGroup.testGroupCode}</td>
                                <td>{testGroup.name}</td>
                                <td>{testGroup.nameOnReport}</td>
                                {/*<td>{testGroup.reportType}</td>*/}
                                <td>{testGroup.testParameters.length}</td>
                                <td>{testGroup.price?.price}</td>
                                <td>{testGroup.price?.priceForPartner}</td>
                                <td>
                                    <Restricted to={UserPermissions.EDIT_TEST_GROUP}>
                                        <CustomDropdownButton
                                            onEdit={()=>{
                                                handelEditPatient(testGroup)
                                            }}
                                            onDelete={()=>{
                                                setTestGroupSelected(testGroup);
                                                setShowDeleteModel(true);
                                            }}
                                            onPriceUpdate={()=>{
                                                setTestGroupSelected(testGroup);
                                                setShowPriceUpdateModel(true);
                                            }}
                                        />
                                    </Restricted>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
            {
                (formik.values.search.trim().length > 1 && testGroupList.length === 0) &&
                <h5 style={{textAlign: "center"}}>No patient found with {formik.values.search}</h5>
            }
            {
                testGroupList &&
                <MyPagination currentPage={testGroupList.pageInfo.currentPage} pageLimit={testGroupList.pageInfo.limit}
                              totalItem={testGroupList.pageInfo.totalItem} onPageClick={(page) => {
                    setCurrentPage(page)
                }}/>
            }

        </Container>
    );
}

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <button
        className={'btn m-0 p-0 pe-1 ps-1'}
        onClick={(e) => {
            e.stopPropagation();
            onClick(e);
        }}
        ref={ref}>
        {children}
    </button>
));

const CustomDropdownButton = ({ onEdit, onDelete, onPriceUpdate }) => {
    const handleEditClick = (e) => {
        e.stopPropagation(); // Stop propagation to prevent the click event from reaching the parent tr
        onEdit();
    };
    const handleUpdatePriceClick = (e) => {
        e.stopPropagation(); // Stop propagation to prevent the click event from reaching the parent tr
        onPriceUpdate();
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation(); // Stop propagation to prevent the click event from reaching the parent tr
        onDelete();
    };

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
                <i className={'bi-three-dots-vertical'} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={handleEditClick}>
                    <i className={'bi-pencil-square me-3'} />Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={handleUpdatePriceClick}>
                    <i className={'bi-currency-rupee me-3'} />Update Price
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleDeleteClick}>
                    <i className={'bi-trash me-3'} />Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
export default TestGroupList;
