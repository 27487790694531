import React from 'react';
import {Button, Col, Container, FloatingLabel, Form, Image, InputGroup, Row} from "react-bootstrap";
import image from './../assets/3823840.jpg'
import {useFormik} from "formik";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {changePassword, resetPassword} from "../service/Reportify";
import {toast} from "../util/toast";

function ChangePassword() {
    const changePasswordHandel = async (values, {resetForm}) => {
        if (values.newPassword && values.confirmPassword && values.newPassword !== values.confirmPassword) {
            toast.warning('Password not match')
            return
        }
        if (values.currentPassword && values.newPassword) changePassword(values)
            .then(value => {
                resetForm();
                toast.success('Password changed successful')
            })
            .catch(reason => {
                toast.error(reason.response?.data?.message)
            })
    }

    const formik = useFormik({
        enableReinitialize: true, initialValues: {
            currentPassword: '', newPassword: '', confirmPassword: ''
        }, onSubmit: changePasswordHandel,
    })


    return (
        <Container style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center">
            <div className={'w-50'}>
                <h4>Change Password</h4>
                <Form onSubmit={formik.handleSubmit}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Current password"
                        className="my-3">
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            type='password'
                            name='currentPassword'
                            onChange={formik.handleChange}
                            value={formik.values.currentPassword}
                        />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label="New Password"
                        className="my-3"
                    >
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            type='password'
                            name='newPassword'
                            onChange={formik.handleChange}
                            value={formik.values.newPassword}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Confirm Password"
                        className="my-3"
                    >
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            type='password'
                            name='confirmPassword'
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                        />
                    </FloatingLabel>
                    <Button variant="primary" type="submit" className={'w-100'}>
                        Save
                    </Button>
                </Form>
            </div>
        </Container>
    );
}

export default ChangePassword;
