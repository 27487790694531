import React, {useEffect, useState, useRef} from 'react';
import {useFormik} from "formik";
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Overlay,
    OverlayTrigger,
    Popover,
    Row,
    ToggleButton,
    Tooltip
} from "react-bootstrap";
import {SketchPicker, SwatchesPicker} from "react-color";

const fontList = [
    'Arial, sans-serif',
    'Verdana, sans-serif',
    'Tahoma, sans-serif',
    'Trebuchet MS, sans-serif',
    'Times New Roman, serif',
    'Georgia, serif',
    'Garamond, serif',
    'Courier New, monospace',
    'Brush Script MT, cursive'
]

const _InitialValue = (textStyle) => {
    return {
        index: textStyle?.index || 1,
        text: textStyle?.text || "",
        style: {
            textAlign: textStyle?.style?.textAlign || "left",
            color: textStyle?.style?.color || "#000",
            fontSize: textStyle?.style?.fontSize || "22",
            fontWeight: textStyle?.style?.fontWeight || "normal",
            fontStyle: textStyle?.style?.fontStyle || "normal",
            textDecoration: textStyle?.style?.textDecoration || "none",
            fontFamily: textStyle?.style?.fontFamily || ""
        }
    }
}

function TextStyle({onChange, textStyle}) {

    const [initialValue, setInitialValue] = useState(_InitialValue(textStyle));
    const [displayColorPicker, setDisplayColorPicker] = useState(-1);
    const target = useRef(null);
    const formik = useFormik(
        {
            initialValues: initialValue,
            enableReinitialize: true
        }
    )

    useEffect(() => {
        onChange(formik.values)
    }, [formik.values])

    const handleAlignment = (align) => {
        formik.setFieldValue('style.textAlign', align)
    };

    const handleColorChange = (newColor) => {
        formik.setFieldValue('style.color', newColor)
    };

    const handleFontSizeChange = (newSize) => {
        formik.setFieldValue('style.fontSize', `${newSize}px`)
    };

    const handleFontChange = (fontName) => {
        formik.setFieldValue('style.fontFamily', fontName)
    };

    const handleFontStyleChange = (style) => {
        const key = Object.keys(style)[0];
        console.log("KEY : ", key)
        formik.setFieldValue(`style.${key}`, style[key])
    };

    return (
        <div>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Col sm={5}>
                        <Form.Control type="text" placeholder="Enter Text" name={'text'} id={'text'} value={formik.values.text}
                                      onChange={formik.handleChange}/>
                    </Col>

                    <Col sm={2}>
                        <ButtonGroup>
                            <Button
                                variant={formik.values.style.textAlign === 'left' ? "primary" : 'outline-secondary'}
                                value="left"
                                onClick={()=>{handleAlignment('left')}}>
                                <i className={'bi-text-left'}/>
                            </Button>
                            <Button
                                variant={formik.values.style.textAlign === 'center' ? "primary" : 'outline-secondary'}
                                value="center"
                                onClick={()=>{handleAlignment('center')}}>
                                <i className={'bi-text-center'}/>
                            </Button>
                            <Button
                                variant={formik.values.style.textAlign === 'right' ? "primary" : 'outline-secondary'}
                                value="right"
                                onClick={()=>{handleAlignment('right')}}>
                                <i className={'bi-text-right'}/>
                            </Button>
                        </ButtonGroup>
                    </Col>

                    <Col sm={2}>
                        <ButtonGroup>
                            <Button
                                variant={formik.values.style.fontWeight === 'normal' ? "outline-secondary" : 'primary'}
                                onClick={() => handleFontStyleChange({fontWeight: formik.values.style.fontWeight === 'normal' ? 'bold' : 'normal'})}><i
                                className={'bi-type-bold'}/></Button>
                            <Button
                                variant={formik.values.style.fontStyle === 'normal' ? "outline-secondary" : 'primary'}
                                onClick={() => handleFontStyleChange({fontStyle: formik.values.style.fontStyle === 'normal' ? 'italic' : 'normal'})}><i
                                className={'bi-type-italic'}/></Button>
                            <Button
                                variant={formik.values.style.textDecoration === 'none' ? "outline-secondary" : 'primary'}
                                onClick={() => handleFontStyleChange({textDecoration: formik.values.style.textDecoration === 'none' ? 'underline' : 'none'})}><i
                                className={'bi-type-underline'}/></Button>
                        </ButtonGroup>
                    </Col>

                    <Col sm={1}>
                        <Button variant={'outline-secondary'} style={{color: formik.values.style.color}} ref={target}
                                onClick={() => setDisplayColorPicker(displayColorPicker === 0 ? -1 : 0)}>
                            <i className={'bi-palette-fill'}/>
                        </Button>
                        <Overlay target={target.current} show={displayColorPicker === 0} placement="bottom">
                            {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                    <SwatchesPicker onChange={(value) => {
                                        handleColorChange(value.hex)
                                        setDisplayColorPicker(-1)
                                    }}/>
                                </Tooltip>
                            )}
                        </Overlay>
                    </Col>

                    <Col sm={1}>
                        <Form.Select
                            id={'style.fontSize'}
                            name={'style.fontSize'}
                            // value={formik.values.style.fontSize}
                            onChange={(event) => {
                            handleFontSizeChange(event.target.value)
                        }}>
                            <option>Size</option>
                            {Array.from({length: 50}, (_, index) => {
                                const size = (index + 1) * 2;
                                return <option key={size} value={size}>{size}</option>;
                            })}
                        </Form.Select>
                    </Col>
                    <Col sm={1}>
                        <Form.Select onChange={(event) => {
                            handleFontChange(event.target.value)
                        }}>
                            <option>Font</option>
                            {
                                fontList.map((value) => {
                                    return <option style={{fontFamily: value}} key={value} value={value}>{value}</option>
                                })
                            }
                        </Form.Select>
                    </Col>

                </Form.Group>
            </Form>
        </div>
    );
}



export default TextStyle;
