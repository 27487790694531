import React, {useEffect, useState} from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import {getPage} from "../service/Reportify";
import {resolveDownload} from "../util/utility";
import HeaderConfig from "./HeaderConfig";
import SignatureConfig from "./SignatureConfig";
import PageConfig from "./PageConfig";
import InvoicePageConfig from "./InvoicePageConfig";
import PDFSetup from "./PDFSetup";

function PageSetup(props) {
    const [pageConfig, setPageConfig] = useState(null)

    useEffect(() => {
        getPage().then(async value => {
            const body = value.data.length > 0 ? value.data[0] : null;
            if (body) {
                let bURL = await resolveDownload(body?.header?.backgroundImageKey)
                if (bURL){
                    body.header.backgroundImageKey = bURL
                }

                let lURL = await resolveDownload(body?.header?.logoImageKey)
                if (lURL){
                    body.header.logoImageKey = lURL
                }

                let pIURL = await resolveDownload(body?.fullPage?.fullPageImageKey)
                if (pIURL){
                    body.fullPage['fullPageImageUrl'] = pIURL
                }
                body.signatures = await Promise.all(body.signatures.map(async (sig) => {
                    sig['signatureImageUrl'] = await resolveDownload(sig.signatureImageKey);
                    return sig;
                }));
            }
            setPageConfig(body)
        }).catch(reason => {
            console.log("Reason : ", reason)
        })
    }, [])


    return (
        <Container className={'my-3'}>
            <Tabs
                defaultActiveKey="pageSetup"
                transition={false}
                id="pageSetupTabs"
                className="mb-3"
                justify>
                <Tab eventKey="pageSetup" title="Print Page Setup">
                    <h5>Page Setup</h5>
                    <PageConfig pageConfig={pageConfig}/>
                </Tab>
                <Tab eventKey="signature" title="Signature">
                    <h5>Report Signature</h5>
                    <SignatureConfig pageConfig={pageConfig}/>
                </Tab>
                <Tab eventKey="header" title="Invoice">
                    <h5>Invoice Setup</h5>
                    <InvoicePageConfig pageConfig={pageConfig}/>
                </Tab>
                {/*<Tab eventKey="pdfSetup" title="PDF Page Setup">*/}
                {/*    <h5>PDF Setup</h5>*/}
                {/*    <PDFSetup pageConfig={pageConfig}/>*/}
                {/*</Tab>*/}
            </Tabs>
        </Container>
    );
}



export default PageSetup;
