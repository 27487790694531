import React, {useEffect, useState} from 'react';
import {getAllUsers} from "../../service/Reportify";
import {toast} from "react-toastify";
import {Button, Col, Container, Row, Stack, Table} from "react-bootstrap";
import {displayDate} from "../../util/date";
import MyPagination from "../MyPagination";
import CreateNewUserModel from "./model/CreateNewUserModel";
import PageTitle from "../compo/PageTitle";
import {tableHeaderColor} from "../../util/utility";

function UserList(props) {
    const [userList, setUserList] = useState([])
    const [user, setUser] = useState(null)
    const [showCreateUserModel, setShowCreateUserModel] = useState(false);
    useEffect(() => {
        getAllUsers()
            .then(value => setUserList(value.data))
            .catch(reason => toast.error(reason?.response?.data?.message))
    },[])

    const handelEditUser = (user) => {
        console.log("edit user", user)
        setUser(user)
        setShowCreateUserModel(true)
    }

    const handelNewUserClick = () => {
        setUser(null);
        setShowCreateUserModel(true)
    }

    const handelUserUpdated = (user) => {
        setUserList(prevState => {
            const existUser = prevState.find(value => value._id === user._id)
            console.log(existUser)
            if (existUser) {
                existUser.permissions = user.permissions;
                existUser.userStatus = user.userStatus;
                existUser.email = user.email;
                existUser.name = user.name;
            }else {
                prevState.push(user)
            }
            return prevState;
        })
    };

    return (
        <Container className={'my-3'}>
            <CreateNewUserModel
                show={showCreateUserModel}
                user={user}
                onDecline={()=>{setShowCreateUserModel(false); setUser(null)}}
                onSave={handelUserUpdated}
                handleClose={()=>{setShowCreateUserModel(false); setUser(null)}}
            />
            <PageTitle title={'User List'} iconName={'bi-people-fill'}>
                <Button variant={"primary"} onClick={handelNewUserClick}><i className={'bi bi-plus'}/> New User</Button>
            </PageTitle>
            <Table striped hover className={'mt-3'}>
                <thead>
                <tr>
                    <th style={tableHeaderColor}>User Id</th>
                    <th style={tableHeaderColor}>Name</th>
                    <th style={tableHeaderColor}>Email</th>
                    <th style={tableHeaderColor}>Permissions</th>
                    <th style={tableHeaderColor}>Status</th>
                    <th style={tableHeaderColor}>Created At</th>
                    <th style={tableHeaderColor}>Action</th>
                </tr>
                </thead>
                <tbody>
                {userList && userList.map((user) => (
                    <tr key={user._id}>
                        <td>{user.userCode}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.permissions.map(per=> <span key={per}>{per},<br/></span>)}</td>
                        <td>{user.userStatus}</td>
                        <td>{displayDate(user.createdAt)}</td>
                        <td><button hidden={user.userType === 'ADMIN'} className={'btn p-0'} onClick={(e)=>{
                            e.stopPropagation()
                            handelEditUser(user);
                        }}><i className={'bi-pencil-square'}/></button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default UserList;
