import React from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { createTestGroupPrice } from '../../service/Reportify';
import { toast } from '../../util/toast';

function TestGroupPriceModel({ testGroup, show, onSave, onDecline, handleClose }) {
    const formik = useFormik({
        initialValues: {
            price: testGroup?.price?.price || '',
            priceForPartner: testGroup?.price?.priceForPartner || '',
        },
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            createTestGroupPrice({...values, testGroupId: testGroup._id})
                .then((value) => {
                    resetForm();
                    toast.success('Price updated');
                    onSave(value.data);
                    handleClose()
                })
                .catch((reason) => {
                    toast.error(reason?.response?.data?.message);
                });
        },
    });

    return (
        testGroup && (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Update Price'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {testGroup.testGroupCode} : {testGroup.name}
                    </h5>
                    <Form id="priceUpdateForm" onSubmit={formik.handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    name="price"
                                    onChange={formik.handleChange}
                                    type="number"
                                    placeholder="Enter Price"
                                    value={formik.values.price}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Price for partner</Form.Label>
                                <Form.Control
                                    name="priceForPartner"
                                    onChange={formik.handleChange}
                                    type="number"
                                    placeholder="Enter Price for Partner"
                                    value={formik.values.priceForPartner}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onDecline}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" form={'priceUpdateForm'}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    );
}

export default TestGroupPriceModel;
