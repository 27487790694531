import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getPatientAndPage} from "../../service/Reportify";
import TestReportPdf from "../../pdf/TestReportPdf";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import * as rdd from 'react-device-detect';
import LoadingComponent from "../compo/LoadingComponent";
import {Button, Spinner} from "react-bootstrap";
import {displayDate} from "../../util/date";

function Device(props) {
    return <div className="device-layout-component">{props.children(rdd)}</div>;
}
function DownloadReport(props) {
    const {patientId, createdAt} = useParams()
    const [patient, setPatient] = useState();
    const [page, setPage] = useState();
    useEffect(() => {
        getPatientAndPage({patientId,createdAt}).then(value => {
            console.log(value.data)
            setPatient(value.data.patient)
            setPage(value.data.page)
        })
    },[])
    // return (
    //     patient && page && <PDFViewer style={{ width: '100%', height: '800px' }} showToolbar={true} >
    //         <TestReportPdf page={page} patient={patient} isLetterhead={'true'}/>
    //     </PDFViewer>
    // );
    return (
        patient && page && <Device>
            {({ isMobile }) => {
                if (isMobile) {
                    return (
                        <PDFDownloadLink
                            document={<TestReportPdf page={page} patient={patient} isLetterhead={'true'}/>}
                            fileName={`${patient.name} report ${displayDate(patient.createdAt)}`}>
                            {({ loading, blob }) =>
                                loading ? <LoadingComponent message={'report is generating please wait'}/>: blob && <DownloadButton/>
                            }
                        </PDFDownloadLink>
                    );
                }
                return (
                    <PDFViewer style={{ width: '100%', height: '100vh' }}>
                        <TestReportPdf page={page} patient={patient} isLetterhead={'true'}/>
                    </PDFViewer>
                );
            }}
        </Device>
    );
}

const DownloadButton = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <Button><i className={'bi-download'}/> Download Report</Button>
        </div>
    );
};

export default DownloadReport;
