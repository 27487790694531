import React from 'react';

const A4PagePreview = ({ fullPage }) => {
    let url = fullPage.fullPageImageUrl || fullPage.fullPageImageKey;
    if (url && typeof url !== "string") {
        url = URL.createObjectURL(url)
    }
    const a4Height = 29.7;
    const divStyle = {
        width: '21cm', // A4 width
        height: a4Height+'cm', // A4 height
        border: '1px solid #000',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
    };

    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };

    const headerStyle = {
        height: fullPage.headerHeight,
        width: '100%',
        border: '1px solid #000',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'red',
        fontSize: '1.5rem',
    };

    const footerStyle = {
        height: fullPage.footerHeight,
        width: '100%',
        border: '1px solid #000',
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'red',
        fontSize: '1.5rem',
    };

    const centerStyle = {
        marginLeft: fullPage.padding,
        marginRight: fullPage.padding,
        marginBottom: fullPage.footerHeight,
        height: 'auto',
        maxWidth: '21cm', // A4 width
        maxHeight: a4Height+'cm', // A4 height
        border: '1px solid #000',
        position: 'absolute',
        bottom: 0,
        left: 0,
        top: fullPage.headerHeight,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'red',
        fontSize: '1.5rem',
        backgroundColor: 'rgba(38,38,38,0.43)'
    };

        return (
            <div style={divStyle}>
                <div style={headerStyle}>{fullPage.headerHeight && 'Header'}</div>
                <div style={centerStyle}>{fullPage.padding && 'Content'}</div>
                <img src={url} alt="A4-sized (21cm * 29.7cm)" style={imageStyle} />
                <div style={footerStyle}>{fullPage.footerHeight && 'Footer'}</div>
            </div>
        );
    };

export default A4PagePreview;
