import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingComponent = ({ message }) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            {message && <p className="mt-2">{message}</p>}
        </div>
    );
};

export default LoadingComponent;
