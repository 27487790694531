import moment from "moment-timezone";

export const displayDate = (dateString) => {
    const istDate = moment.utc(dateString).local();
    return istDate.format('DD-MMM-yyyy hh:mm A');
}

export const getReportingDateFormate = (date) => {
    return moment(date).format('DD-MMM-yyyy hh:mm:A')
}

export const displayRelativeDate = (dateString) => {
    return moment(dateString).fromNow();
}
