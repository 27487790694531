import React, {useEffect, useState} from 'react';
import {Navbar, Container, Nav, NavDropdown} from "react-bootstrap";
import labLogo from "../assets/laboratory.svg"
import {Link, useLocation} from "react-router-dom";
import {useAuth} from "../contexts/authenticationContext";
import Restricted from "../contexts/permissions/Restricted";
import {UserPermissions} from "../contexts/permissions/userPermissions";

const MyNavbar = ({appName}) => {
    const {isLoggedIn, logout, user} = useAuth();
    const location = useLocation();
    const [active, setActive] = useState('home');

    useEffect(()=>{
        const path = location.pathname.split('/')[1] || 'home';
        setActive(path)
    },[location])

    return (
        (isLoggedIn && <Navbar className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} to="/home">
                    <img
                        alt={appName}
                        src={labLogo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top mx-3"
                    />{appName}
                </Navbar.Brand>
                <Navbar.Collapse>
                    <Nav className="me-auto" activeKey={active} onSelect={(eventKey, e) => setActive(eventKey)}>
                        <Nav.Link as={Link} to="/home" eventKey={'home'}>Home</Nav.Link>
                        <Nav.Link as={Link} to="/registration" eventKey={'registration'}>Registration</Nav.Link>
                        <Restricted to={UserPermissions.CREATE_REPORT}>
                            <Nav.Link as={Link} to="/report" eventKey={'report'}>Report</Nav.Link>
                        </Restricted>
                        <Nav.Link as={Link} to="/patient" eventKey={'patient'}>Patient</Nav.Link>
                        <Nav.Link as={Link} to="/test-group" eventKey={'test-group'}>Test Group</Nav.Link>
                        <Restricted to={UserPermissions.MANAGE_USER}>
                            <Nav.Link as={Link} to="/user" eventKey={'user'}>User</Nav.Link>
                        </Restricted>
                        <Restricted to={UserPermissions.MANAGE_REFERER}>
                            <Nav.Link as={Link} to="/referer" eventKey={'referer'}>Referer</Nav.Link>
                        </Restricted>
                        <Restricted to={UserPermissions.MANAGE_PAGE_SETUP}>
                            <Nav.Link as={Link} to="/page-setup" eventKey={'page-setup'}>Page Setup</Nav.Link>
                        </Restricted>
                    </Nav>
                    <Nav>
                        <NavDropdown title={user?.name || user?.userType} id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/change-password">Change Password</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={logout}>Sign Out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>)
    );
}

export default MyNavbar;
