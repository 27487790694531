import React, {useEffect} from 'react';
import {Field, FieldArray, Form as FormikForm, Formik, useFormik} from 'formik';
import {resolveUploadFile} from "../util/utility";
import {createPageHeader, createPageSignature} from "../service/Reportify";
import {toast} from "../util/toast";
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {forEach} from "react-bootstrap/ElementChildren";

const SignaturePreview = ({signatures}) => {
    const divStyle = {
        width: '21cm', // A4 width
        height: '4cm',
        border: '1px solid #000',
        paddingTop: '5mm',
        paddingBottom: '5mm',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
    };
    return <div style={divStyle}>
        <Stack direction="horizontal" gap={3} className="align-items-end">
            {signatures &&
                signatures.map((signature) => {
                    let imageFile = signature.signatureImageKey;
                    if (typeof imageFile !== "string") {
                        imageFile = URL.createObjectURL(imageFile);
                    }else if (signature.signatureImageUrl !== '') {
                        imageFile = signature.signatureImageUrl;
                    }
                    return (
                        <div className="p-2 ms-auto">
                            <img src={imageFile} style={{ height: '1cm', width: '3cm' }}  alt={'signature'}/>
                            <h6 style={{fontSize: '16px'}}>{signature.title}</h6>
                            <p style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{signature.subTitle}</p>
                        </div>

                    );
                })}
        </Stack>

    </div>
}

const Signature = ({signature, onChange, removeParameter, index}) => {
    const formik = useFormik({
        initialValues: signature, enableReinitialize: true, onSubmit: () => {
        }
    })

    useEffect(() => {
        onChange(formik.values)
    }, [formik.values])

    return (<div>
        <Row>
            <Form.Group as={Col} sm={3}>
                <Form.Label>Signature Image</Form.Label>
                <Stack direction={"horizontal"}>
                    <Form.Control
                        type="file"
                        name="signatureImageKey"
                        id="signatureImageKey"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            formik.setFieldValue('signatureImageKey', file);
                        }}/>
                    <button
                        className={'btn'}
                        onClick={(event) => {
                            event.preventDefault()
                            // setFieldValue('signatureImageKey','')
                        }}><i className={'bi-trash-fill'}/></button>
                </Stack>
            </Form.Group>

            <Form.Group as={Col} sm={3}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    name={'title'}
                    id={'title'}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                />
            </Form.Group>

            <Form.Group as={Col} sm={3}>
                <Form.Label>SubTitle</Form.Label>
                <Form.Control
                    as="textarea"
                    name={'subTitle'}
                    id={'subTitle'}
                    value={formik.values.subTitle}
                    row={3}
                    onChange={formik.handleChange}/>
            </Form.Group>
            <Form.Group as={Col} sm={3}>
                <Form.Label/>
                <br/>
                <Button size={"sm"} className={'ms-auto mt-2'} variant={"outline-danger"}
                        onClick={() => {
                            removeParameter(index)
                        }}><i className={'bi-x-lg'}/></Button>
            </Form.Group>
        </Row>

    </div>)
}


function SignatureConfig({pageConfig}) {
    const buildInitialValue = (pageConfig) => {
        const data = [];
        if (pageConfig?.signatures.length > 0) {
            pageConfig.signatures.map((sig) => {
                data.push({
                    signatureImageUrl: sig?.signatureImageUrl || '',
                    signatureImageKey: sig?.signatureImageKey || '',
                    title: sig?.title || '',
                    subTitle: sig?.subTitle || '',
                })
            });
        } else {
            data.push({
                signatureImageKey: '', title: '', subTitle: '',
            })
        }
        return {
            signatures: data
        }
    }

    const handelSubmit = async (values) => {
        const body = [];
        for (const signature of values.signatures) {
            if (typeof signature.signatureImageKey !== "string") {
                const file = signature.signatureImageKey;
                body.push({...signature, signatureImageKey: await resolveUploadFile(file)})
            }else {
                body.push(signature)
            }
        }

        createPageSignature(body).then(value => {
            toast.success('Page saved');
            console.log('values ', value)
        }).catch(reason => {
            toast.error(reason.response.data.message)
            console.log("Reason", reason)
        })
    }

    return (<div>
        <Formik
            enableReinitialize={true}
            initialValues={buildInitialValue(pageConfig)}
            onSubmit={handelSubmit}>
            {(props) => {
                const values = props.values;
                const setFieldValue = props.setFieldValue;
                return <FormikForm>
                    <FieldArray name={'signatures'}>
                        {({push: pushParameter, remove: removeParameter}) => {
                            return <div>
                                {
                                    values?.signatures.map((signature, index) => {
                                        return <div key={index} className={'my-3'}>

                                            <Signature signature={signature} onChange={(value) => {
                                                setFieldValue(`signatures[${index}]`, value)
                                            }} removeParameter={removeParameter} index={index}/>
                                        </div>
                                    })
                                }
                                <Button size={"sm"} className={'ms-auto mt-2'} variant={"primary"}
                                        onClick={() => {
                                            pushParameter({
                                                signatureImageKey: '', title: '', subTitle: '',
                                            })
                                        }}><i className={'bi-plus-lg'}/>Add Signature</Button>
                            </div>
                        }
                        }

                    </FieldArray>
                    <div className={'my-3'}>
                        <Button type={"submit"}>Save</Button>
                    </div>
                    <div className={'my-3'}>
                        <h3>Preview</h3>
                        <SignaturePreview signatures={values.signatures}/>
                    </div>

                </FormikForm>

            }}
        </Formik>

    </div>);
}

export default SignatureConfig;
