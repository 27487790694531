import React, {useEffect, useState} from 'react';
import PermissionContext from './PermissionContext';
import {useAuth} from "../authenticationContext";
import {UserPermissions, UserType} from "./userPermissions";

const PermissionProvider = ({ children }) => {
    const {user} = useAuth();
    const [permissions, setPermissions] = useState([]);
    const isAllowedTo = (permission) => {
        if (user?.userType === UserType.ADMIN || user?.userType === UserType.SUPER_ADMIN) return true;
        if (permission.includes(UserPermissions.ALL)) return true;
        return permissions.includes(permission)
    };

    useEffect(()=>{
        setPermissions(user?.permissions || [])
    },[user])

    return (
        <PermissionContext.Provider value={{ isAllowedTo }}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
