import React, {useEffect, useState} from 'react';
import {getHomeData} from "../service/Reportify";
import {Button, Card, Col, Container, ProgressBar, Row, Stack} from "react-bootstrap";
import CardWithIconProgress from "./compo/CardWithIconProgress";
import CardWithIcon from "./compo/CardWithIcon";
import Restricted from "../contexts/permissions/Restricted";
import {UserPermissions} from "../contexts/permissions/userPermissions";
import PageTitle from "./compo/PageTitle";

const cardStyle = () => {
    return {
        width: '200px',
    }
}

function Home(props) {
    const [homeData, setHomeData] = useState(null);
    useEffect(() => {
        getHomeData().then(value => {
            setHomeData(value.data)
        }).catch(reason => {
            console.log(reason)
        })
    }, [])
    return (
        homeData && <Container className={'my-3'} >
            <PageTitle title={'Dashboard'} iconName={'bi-reception-4'}/>
            <Stack>
                {homeData?.invoice && <Card className={'my-3'} style={{borderRadius: 0}}>
                    <Card.Title className={'mt-3 ms-3'}>
                        <PageTitle title={'Sale'} iconName={'bi-graph-up-arrow'}/>
                    </Card.Title>
                    <Card.Body>
                        <Row>
                            <Col>
                                <CardWithIconProgress text={homeData.invoice.today.total} title={'Today'}
                                                      biIcon={'bi-currency-rupee'} iconColor={'tomato'}
                                                      progress={(homeData.invoice.today.total / homeData.invoice.yesterday.total) * 100}/>
                            </Col>
                            <Col>
                                <CardWithIconProgress text={homeData.invoice.thisWeek.total} title={'This Week'}
                                                      biIcon={'bi-calendar-week'} iconColor={'slateBlue'}
                                                      progress={(homeData.invoice.thisWeek.total / homeData.invoice.lastWeek.total) * 100}/>
                            </Col>
                            <Col>
                                <CardWithIconProgress text={homeData.invoice.thisMonth.total} title={'This Month'}
                                                      biIcon={'bi-bar-chart'} iconColor={'olive'}
                                                      progress={(homeData.invoice.thisMonth.total / homeData.invoice.lastMonth.total) * 100}/>
                            </Col>
                            <Col>
                                <CardWithIconProgress text={homeData.invoice.lastMonth.total} title={'Last Month'}
                                                      biIcon={'bi-graph-up-arrow'} iconColor={'tomato'} progress={100}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>}
                <Card className={'my-3'} style={{borderRadius: 0}}>
                    <Card.Title className={'mt-3 ms-3'}>
                        <PageTitle title={'Patient'} iconName={'bi-person-arms-up'}/>
                    </Card.Title>
                    <Card.Body>
                        <Row>
                            <Col>
                                <CardWithIcon text={homeData.patient.today} title={'Today'} biIcon={'bi-people'}  iconColor={'DodgerBlue'} compare={homeData.patient.yesterday}/>
                            </Col>
                            <Col>
                                <CardWithIcon text={homeData.patient.thisWeek} title={'This Week'} biIcon={'bi-pie-chart'} iconColor={'green'} compare={homeData.patient.lastWeek}/>
                            </Col>
                            <Col>
                                <CardWithIcon text={homeData.patient.thisMonth} title={'This Month'} biIcon={'bi-bar-chart-steps'} iconColor={'lightSeaGreen'} compare={homeData.patient.lastMonth}/>
                            </Col>
                            <Col>
                                <CardWithIcon text={homeData.patient.total} title={'Total'} biIcon={'bi-graph-up-arrow'} iconColor={'red'}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className={'my-3'} style={{borderRadius: 0}}>
                    <Card.Title className={'mt-3 ms-3'}>
                        <PageTitle title={'Report'} iconName={'bi-card-checklist'}/>
                    </Card.Title>

                    <Card.Body>
                        <Row>
                            <Col>
                                <CardWithIcon text={homeData.patientTestGroup.today} title={'Today'} biIcon={'bi-file-earmark-post'} iconColor={'darkMagenta'} compare={homeData.patientTestGroup.yesterday}/>
                            </Col>
                            <Col>
                                <CardWithIcon text={homeData.patientTestGroup.thisWeek} title={'This Week'} biIcon={'bi-bar-chart-steps'} iconColor={'lightSeaGreen'} compare={homeData.patientTestGroup.lastWeek}/>
                            </Col>
                            <Col>
                                <CardWithIcon text={homeData.patientTestGroup.thisMonth} title={'This Month'} biIcon={'bi-pie-chart'} iconColor={'green'} compare={homeData.patientTestGroup.lastMonth}/>
                            </Col>
                            <Col>
                                <CardWithIcon text={homeData.patientTestGroup.total} title={'Total'} biIcon={'bi-graph-up-arrow'} iconColor={'red'}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Stack>
        </Container>
    );
}

export default Home;
